<template>
    <div class="modal fade" ref="packageInfoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content packages-detail-popup-content">
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                <!-- <div class="modal-header">
                <h5 class="modal-title" id="loginModalLabel">Modal title</h5>
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                </div> -->
                <div class="modal-body">
                    <section class="packages-detail-popup">
                        <div class="container-fluid">
                            <div class="login-card">
                                <template v-if="selectedPackageName === 'ProductDetail'">
                                    <h2> Product Detail </h2>
                                    {{ selectedPackage }}
                                </template>
                                <template v-else-if="selectedPackageName === 'TermsConditions'">
                                    <h2> Terms & Conditions </h2>
                                    <div v-html="selectedPackage"></div>
                                </template>
                                <template v-else-if="selectedPackageName === 'WhyBuy'">
                                    <h2> Why Buy </h2>
                                    <div v-html="selectedPackage"></div>
                                </template>
                                <template v-else-if="selectedPackageName === 'Coverages'">
                                    <h2> Coverages </h2>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion" id="accordionExample">
                                            <div v-for="(value, index) in selectedPackage" class="accordion-item">
                                                <h2 class="accordion-header" :id="'heading-' + index">
                                                    <button class="accordion-button" type="button" :data-bs-toggle="'collapse'" :data-bs-target="'#collapse' + index" :aria-expanded="index === 0 ? 'true' : 'false'" :aria-controls="'collapse-' + index">
                                                        {{ value.cover }}
                                                    </button>
                                                </h2>
                                                <div :id="'collapse' + index" :class="['accordion-collapse', 'collapse', { 'show': index === 0 }]" :aria-labelledby="'heading-' + index" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {{ value.cover_limit }} 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="selectedPackageName === 'Exclusions'">
                                    <h2> Exclusions </h2>
                                    <div v-html="selectedPackage"></div>
                                </template>   
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
export default {
  
  data() {
      return {
        selectedPackage: [],
        selectedPackageName: ''
      }
    },
    methods: {
         
    },
    mounted(){
        this.modal = new Modal(this.$refs.packageInfoModal);
        // this.modal._element.addEventListener('shown.bs.modal', () => {
        //     this.beforeOpen();
        // });
    },
    watch:{
        validations:function () {
      }
    }
}
</script>