<template>
  <div>
    <div v-if="loader" id="preloader" class="loader" >
      <div class="spinner">
      </div>
    </div>
    <Header />
    <router-view :key="$route.fullPath"></router-view>
    <Footer />
  </div>
</template>

<script>
    import Header from '@/components/layouts/Header.vue'
    import Footer from '@/components/layouts/Footer.vue'
    export default {
      components:{
        Header,
        Footer,
      },
      watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'InXureHub – Banao Har Lamha Mehfooz';
            }
        },
    },
    created(){
        this.loader=false
      }
    }
</script>

