<template>
    <div class="modal fade" ref="loginModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered .modal-sm">
            <div class="modal-content login-popup-content">
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                <!-- <div class="modal-header">
                <h5 class="modal-title" id="loginModalLabel">Modal title</h5>
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                </div> -->
                <div class="modal-body">
                    <section class="login popup-login">
                        <div class="container-fluid">
                            <div class="login-card">
                                <h2>Hello Again !</h2>
                                <p>Please Sign in to continue with us</p>
                                <span class="text-danger mb-3" v-if="validations.length > 0">{{validations}}</span>
                                <div class="form-group gradient-border">
                                    <input type="text" v-model="user_name" placeholder="User Name" class="form-control">
                                    <span class="text-danger" v-if="validations.user_name">{{validations.user_name[0]}}</span>
                                </div>
                                <div class="form-group gradient-border group_btn">
                                    <input type="password" v-model="password" placeholder="Passsword" class="form-control"> <span class="btn_pwd"><i class="fa fa-eye"></i></span>
                                    <span class="text-danger" v-if="validations.password">{{validations.password[0]}}</span>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <div class="chk-inner">
                                            <label class="check-holder">
                                                Remember Password
                                                <input type="checkbox" id="remember_pwd" name="remember_pwd" value="Yes">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col align-right"><a href="#">Forgot Password?</a></div>
                                </div>
                                <div class="form-group"><button class="btn btn-signin" @click="loginFormHandle" >Sign IN</button></div>
                                <div class="align-center">
                                    <span class="register-btn" style="cursor:pointer" @click="this.$parent.openSignup()"> Not a User? Please Register</span>
                                </div>
                                    
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
export default {
  
  data() {
      return {
        user_name: '',
        password: '',
        validations:[],
      }
    },
    methods: {
         async loginFormHandle() {
             let _this=this;
            _this.validations=[]
            let config = {
                method: 'post',
                url: 'https://inxure.application.zijsoft.com/api/login',
                data: {
                    'user_name':this.user_name,
                    'password':this.password
                },
            }

            axios(config)
            .then(function (response) {
                console.log(response)
                if(response.status==500){
                    _this.validations=response.data.message;
                    return false;
                }
                if(response.status==200) {
                    let user = response.data.data;
                    localStorage.setItem('user', JSON.stringify(user));
                    let LocalToken = response.data.data.token;
                    localStorage.setItem('local_token', LocalToken);

                    let OzoneToken = response.data.data.accessToken;
                    localStorage.setItem('ozone_token', OzoneToken);
                    _this.modal.hide()
                    return false;
                    
                }

            })
            .catch(function (response) {
              _this.validations=(response.response.data.errors);
               if(response.response.status==404){
                  
               }
            });
        },
    },
    mounted(){
        this.modal = new Modal(this.$refs.loginModal, {
            backdrop: 'static'
        });
      let user = localStorage.getItem('user')
      if(user){
        console.log(user);
      }
    },
    watch:{
        validations:function () {
      }
    }
}
</script>