<template>
  <div class="banner">
      <img src="../assets/images/faq-banner.jpg" class="w-100" />
  </div>

<section class="faq-sec">
	<div class="container">
		<div class="accordion" id="faq">
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingOne">
				<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
					Q:- What is an InxureHub?
				</button>
				</h2>
				<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> InxureHub is a marketplace for all your insurance needs; it provides a paperless journey between you and the insurance provider; you can perform all the steps of buying an insurance policy from the comfort of your home. You can file a claim and service your policy from within the app as well. </p>
				</div>
				</div>
			</div>
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwo">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
					Q:- Why Should I use InxureHub?
				</button>
				</h2>
				<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> InxureHub removes all the hassles for you whether it is buying a policy, requesting policy servicing or filing a claim. It also provides a comparison of the best available plans and coverages in Pakistan. </p>
				</div>
				</div>
			</div>
			<div class="accordion-item">
				<h2 class="accordion-header" id="headingThree">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
					Q:- What types of Insurance do you offer on your application?
				</button>
				</h2>
				<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> At the moment we are offering the following products from different companies </p>
					<ul>
						<li> Life Insurance </li>
						<li> Motor Insurance </li>
						<li> Health Insurance </li>
						<li> Travel </li>
						<li> Personal Accident Insurance </li>
						<li> Home Insurance </li>
					</ul>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingFour">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
					Q:- What are my payment options?
				</button>
				</h2>
				<div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> Your payment options are credit card and debit card at the moment. </p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingFive">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
					Q:- How do I choose a policy that is right for me?
				</button>
				</h2>
				<div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> You can choose the policy that is right for you by comparing the different policies and reading the information provided in the accompanying brochures. </p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingSix">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
					Q:- What are insurance company ratings?
				</button>
				</h2>
				<div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> Insurance companies are rated by independent bodies on different parameters. </p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingSeven">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
					Q:- How do I cancel my policy?
				</button>
				</h2>
				<div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> You can request to cancel your policy from the policy servicing menu of the InxureHub APP. </p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingEight">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
					Q:- My promo code doesn’t work?
				</button>
				</h2>
				<div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> Your promo may have already been used or may have been expired. </p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingNine">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
					Q:- Why is my claim taking so long?
				</button>
				</h2>
				<div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> Claims are processed by the insurance company and they have their own criteria for verifying and processing claims. </p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTen">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
					Q:- Why was my policy rejected?
				</button>
				</h2>
				<div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> Your policy may have been rejected due to a number of reasons the most prevalent of which is the upload of incorrect information, KYC failure etc. </p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingEleven">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
					Q:- What is verisys?
				</button>
				</h2>
				<div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#faq">
				<div class="accordion-body">
					<p> Verisys is NADRA’s system of ID verification. The insurance company verifies customer ID before policy issuance. </p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwelve">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
					Q:- How will I make sure that you won’t misuse my signatures or CNIC?
				</button>
				</h2>
				<div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>Your personal details are saved in an encrypted database, and when scanned your CNIC and signatures will be scanned it will be digitally watermarked. </p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingThirteen">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
					Q:- Why are my policy numbers different?
				</button>
				</h2>
				<div id="collapseThirteen" class="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>Each insurance company and Insurance product has its own format for the policy number.</p>
					
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingFourteen">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
					Q:- What is the need for uploading these images or document while claiming intimation?
				</button>
				</h2>
				<div id="collapseFourteen" class="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>These images or supporting documents help in expediting the claim process.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingFifteen">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
					Q:- What is a pre-policy document?
				</button>
				</h2>
				<div id="collapseFifteen" class="accordion-collapse collapse" aria-labelledby="headingFifteen" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>The pre-policy document is a confirmation that you have placed an order for the policy from InxureHub.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingSixteen">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
					Q:- What is an endorsement/policy servicing?
				</button>
				</h2>
				<div id="collapseSixteen" class="accordion-collapse collapse" aria-labelledby="headingSixteen" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>Any change in the insurance policy is called an endorsement.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingSeventeen">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
					Q:- How can I request policy servicing?
				</button>
				</h2>
				<div id="collapseSeventeen" class="accordion-collapse collapse" aria-labelledby="headingSeventeen" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>You can request policy servicing from the account menu of the InxureHub APP. Select your active policy, choose servicing type and enter the new details, Insurance company have the right to accept or reject your request.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingEighteen">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
					Q:- The scanner isn’t identifying my card details.
				</button>
				</h2>
				<div id="collapseEighteen" class="accordion-collapse collapse" aria-labelledby="headingEighteen" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>You can manually input the card details if your card isn’t auto scanned.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingNineteen">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
					Q:- The scanner puts forth false details.
				</button>
				</h2>
				<div id="collapseNineteen" class="accordion-collapse collapse" aria-labelledby="headingNineteen" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>Please rectify the mistakes and then proceed.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwenty">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
					Q:- What are the perks of being a ‘founder member’?
				</button>
				</h2>
				<div id="collapseTwenty" class="accordion-collapse collapse" aria-labelledby="headingTwenty" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>Being a founder member gets you perks such as the founder member badge; free accident and hospitalization cover for one year and a promo for 10% off your next purchase.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwentyOne">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
					Q:- How do I file a claim?
				</button>
				</h2>
				<div id="collapseTwentyOne" class="accordion-collapse collapse" aria-labelledby="headingTwentyOne" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>Filing a claim with an InxureHub is very easy; just go to claims, select policy and then file a claim with all the required documents and details.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwentyTwo">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapse%TwentyTwo">
					Q:- How long will my policy remain in effect?
				</button>
				</h2>
				<div id="collapseTwentyTwo" class="accordion-collapse collapse" aria-labelledby="headingTwentyTwo" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>Every policy has a different expiry date and it remains in effect till that expiry date.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwentyThree">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
					Q:- What is premium?
				</button>
				</h2>
				<div id="collapseTwentyThree" class="accordion-collapse collapse" aria-labelledby="headingTwentyThree" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>It is the amount paid by the policy owner for the purchase of a life insurance policy. Subsequently, renewal premiums are paid by due dates or within the grace period to ensure that the policy remains in force and that the benefits available under the policy remain intact.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwentyFour">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
					Q:- What is a policy document?
				</button>
				</h2>
				<div id="collapseTwentyFour" class="accordion-collapse collapse" aria-labelledby="headingTwentyFour" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>A policy document is printed and signed an insurance contract between the policy owner and InxureHub. Policy documents consist of the policy schedule, stamp duty page, standard policy conditions and supplementary contracts (if provided for in the policy).</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwentyFive">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
					Q:- What is a total loss?
				</button>
				</h2>
				<div id="collapseTwentyFive" class="accordion-collapse collapse" aria-labelledby="headingTwentyFive" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>If the insurance company deems that the cost of the repair of an accidental vehicle is more than 50% of the vehicle’s current market value, it is likely that such vehicle will be declared as Total Loss as it is uneconomical to get the vehicle repaired.</p>
				</div>
				</div>
			</div>

			<div class="accordion-item">
				<h2 class="accordion-header" id="headingTwentySix">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
					Q:- Can the insurance provider cancel my policy?
				</button>
				</h2>
				<div id="collapseTwentySix" class="accordion-collapse collapse" aria-labelledby="headingTwentySix" data-bs-parent="#faq">
				<div class="accordion-body">
					<p>Yes, the insurance company reserves right to cancel the policy at any time.</p>
				</div>
				</div>
			</div>

		</div>
	</div>
</section>
</template>
