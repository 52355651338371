<template>
    <section class="login">
      <div class="container-fluid">
          <img src="assets/images/logo-icon.png" alt="" class="login-logo">
        <!-- <div class="row">
          <div class="col-lg-6">
                  <img src="assets/images/logo-icon.png" alt="" class="login-logo">
              </div>
              <div class="col-lg-6">  
              </div>
        </div> -->
        <div class="text-box-login">
          <h1 class="font-sacramento">Secure best</h1>
          <h2>insurance plan & enjoy the life at every stage</h2>
          <h3>Banao Har Lamha Mehfooz</h3>
        </div>
        <div class="login-card">
              <h2>Hello Again !</h2>
              <p>Please Sign up to continue with us</p>
              <div class="form-group gradient-border">
                <input type="text" v-model="first_name" placeholder="First Name" class="form-control">
                <span class="text-danger" v-if="finalValidationsFields.first_name"
                >
                  {{finalValidationsFields.first_name[0]}}
                </span>
              </div>
              <!-- <div class="form-group gradient-border">
                <input type="text" v-model="middle_name" placeholder="Middle Name" class="form-control">
                <span class="text-danger" v-if="validations.middle_name">{{validations.middle_name[0]}}</span>
              </div> -->
              <div class="form-group gradient-border">
                  <input type="text" v-model="last_name" placeholder="Last Name" class="form-control">
                  <span class="text-danger" v-if="finalValidationsFields.last_name"
                >
                  {{finalValidationsFields.last_name[0]}}
                </span>
              </div>
              <div class="form-group gradient-border">
                  <input type="text" v-model="mobile_no" placeholder="Mobile No," class="form-control">
                  <span class="text-danger" v-if="finalValidationsFields.mobile_no"
                >
                  {{finalValidationsFields.mobile_no[0]}}
                </span> 
              </div>
              <!-- <div class="form-group gradient-border">
                  <input type="text" v-model="cnic" placeholder="CNIC" class="form-control">
                  <span class="text-danger" v-if="validations.cnic">{{validations.cnic[0]}}</span>  
              </div> -->
              
              <div class="form-group gradient-border">
                <input type="email" v-model="email" placeholder="Email" class="form-control">
                <span class="text-danger" v-if="finalValidationsFields.email"
                >
                  {{finalValidationsFields.email[0]}}
                </span> 
              </div>

              <div class="form-group gradient-border">
                <input type="text" v-model="username" placeholder="User Name" class="form-control">
                <span class="text-danger" v-if="finalValidationsFields.username"
                >
                  {{finalValidationsFields.username[0]}}
                </span> 
              </div>

              <div class="form-group gradient-border">
                  <input type="text" v-model="password" placeholder="Password" class="form-control">
                  <span class="text-danger" v-if="finalValidationsFields.password"
                >
                  {{finalValidationsFields.password[0]}}
                </span> 
              </div>

              <!-- <div class="form-group gradient-border">
                <input type="file" @change="getProfileImg" value="" />
                <span class="text-danger" v-if="validations.avatar">{{validations.avatar[0]}}</span> 
                <img :src="profile_img" />
              </div> -->
              
              <div class="form-group"><button class="btn btn-signin" @click="signupForm" >Sign Up</button></div>
          </div>
      </div>
  </section>
</template>
<script>
    import axios from 'axios'


export default {
  name: 'Signup',
  components: {
   
  },
  data() {
      return {
        first_name: '',
        // middle_name: '',
        last_name: '',
        mobile_no: '',
        // cnic: '',
        password: '',
        email: '',
        // profile_img: '',
        username: '',
        validations:{},
        finalValidationsFields: {}
      }
    },
    methods: {
        resetValidationsMessage:function(){
          this.validations={};
        },
        getProfileImg(event) {
            this.profile_img = event.target.files[0]
        },
         async signupForm() {
             let _this=this;
             this.resetValidationsMessage();
             let config = {
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: 'https://developer.inxurehub.com/api/register',
                data: {
                  first_name:_this.first_name,
                  // middle_name:this.middle_name,
                  last_name:_this.last_name,
                  username:_this.username,
                  mobile_no:_this.mobile_no,
                  // cnic:this.cnic,
                  password:_this.password,
                  email:_this.email,
                  // profile_img:this.profile_img,
                },
            }

             axios(config)
             .then((response) => {
                  // console.log(response)
                  if(response.status==200){
                    _this.first_name="",
                    // _this.middle_name="",
                    _this.last_name="",
                    _this.mobile_no="",
                    // _this.cnic="",
                    _this.password="",
                    _this.email="",
                    _this.country_code="",
                    _this.avatar="",
                    _this.username=""
                    this.$router.push('/login')
                  }
              })
              .catch((e) => {
                _this.validations=e.response.data.errors.errors;
                var obj = {};
                for (var i = 0; i < _this.validations.length; i++) {
                  obj[(_this.validations[i].field)] = Object.values([_this.validations[i].message])
                }
                 _this.finalValidationsFields = obj
                console.log(_this.finalValidationsFields)
                if(e.response.status==404){
                  
                }
              });
        },
    },
    mounted(){
      let user = localStorage.getItem('user')
      if(user){
        console.log(user);
        this.$router.push('/')
      }
    },
      watch:{
        validations:function(){},
      }
}
</script>