import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Feature from '../views/Feature.vue'
import FeatureForm from '../views/FeatureForm.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Faq from '../views/Faq.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermOfUser from '../views/TermOfUser'
import ComingSoon from '../views/ComingSoon'
import Success from '../views/Success'
import Error from '../views/Error'
import ForgotPassword from '../views/ForgotPassword'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/feature',
    name: 'feature',
    component: Feature
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/feature-form',
    name: 'feature-form',
    component: FeatureForm
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/term-of-user',
    name: 'term-of-user',
    component: TermOfUser
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: ComingSoon
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior: (to, from, savedPosition) => {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else if (to.hash) {
  //     return {
  //       selector: to.hash
  //     };
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // }
})
router.beforeEach(function (to, from, next) { 
  setTimeout(() => {
      window.scrollTo(0, 0);
  }, 100);
  next();
});

export default router
