<template>
    <div class="coming-soon">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1> Coming Soon... </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ComingSoon',
  components: {
    
  },
  data() {
      return {
        
      }
    },
    methods:{
    }
}
</script>
