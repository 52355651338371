<template>
    <div class="banner">
      <img src="../assets/images/term-use-banner.jpg" class="w-100" />
  </div>
  
  <section class="content-page">
      <div class="container">
          <h1> Terms of Use </h1>
          <p> Welcome to inxurehub.com also hereby known as “we”, “us” “they” “our” or “inxurehub” or “inxurehub (Private) Limited”. We run a website located at inxurehub.com and other websites, domains, sub-domains, mobile applications and tools (collectively, the “Portal”). Through our Portal we operate as a web-based corporate insurance agent (“Agent”) of various participating insurance companies (“Insurers”) bringing potential customers (“Customer”/ “Customers”, “Users” / “User”, “You “or “Your”) of insurance policies and products (“Policy or Product”) to the Insurers, by displaying various Insurance Policies and Products of Insurers setting out their pricing, coverage etc., for the Customer to compare and make a choice of purchasing a Product or Policy which best suits the Customer’s needs and requirements (“Services”). All such purchases shall be made by the Customer directly from the Insurer and we take no responsibility of the accuracy or reliability of the information displayed on our Portal and shall only assist the Customer in purchasing the Policies and Products of the Insurers.</p>
          <p> Please read these terms and conditions (“Terms” / “User Agreement”), in its entirety, which govern your access and use of the Portal. These also contain important information about your rights and obligations, as well as limitations and exclusions that apply to your use of our Portal.</p>
          <h2> A. CONDITIONS OF USE </h2>
          <p> By using the Portal, you hereby accept these Terms (including the linked information herein) and represent that you agree to comply with these Terms. This User Agreement is deemed effective upon your use of the Portal which signifies your acceptance of these Terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Portal.</p>
          <p> inxurehub reserves the unfettered right to change, modify, add, or remove portions of these Terms at any time without any prior notification. Changes will be effective when posted on the Portal with no other notice provided. Please keep yourself updated regarding these Terms by frequently visiting our Portal. Your continued use of the Portal following the posting of changes to these Terms constitutes your acceptance of those changes.</p>
          <h2> 1. Registration and User’s Account </h2>
          <p> In order for us to provide you with information regarding certain Insurance Products and Policies of Insurers, we require you provide us with certain information, including your personal information, about yourself as part of the registration process to successfully make a User account (“Account”). Your action of successful registration constitutes your acceptance of these Terms and the Privacy Policy of inxurehub. The data required for registration provided by the User must be complete and accurate. The User is responsible for updating its/his/her own data that can be amended at any time from the respective interface of the Portal. We take no responsibility over the use of the User Account and expressly disclaim any liability therefrom. Should a User suspect that any unauthorized party might be using its/his/her account, he/she/it is under an obligation to notify us immediately, by emailing us at info@inxurehub.com. By submitting the personal details at the time of registration and pursuant to the use of the Portal, the User hereby affirmatively consents to our use of its/his/her personal details for contacting it/him/her directly in order to provide /him/her the Services, including but not limited to occasionally sending notifications, text messages with promotional offers, service updates and reaching out to the User to survey regarding the quality of the Portal. Notwithstanding anything contained herein, inxurehub at its sole discretion reserves the right to restrict, suspend, or terminate the User’s Account if it is believed that the User is or may be in breach of this User Agreement or law or is misusing the Services.</p>
          <p> Upon registration on the Portal, inxurehub shall assign an account and issue a User ID and password (the latter shall be chosen by a User during registration) to each User (“User ID”). Each User shall be solely responsible for maintaining the confidentiality and security of their User ID and for all activities that occur under their Account. No User must share, assign, or permit the use of its/his/her User ID by another person outside of the User’s own business. Users agrees to notify inxurehub immediately if they become aware of any unauthorized use of their password or account or any other breach of security of their Account.</p>
          <h2> 2. User’s Responsibilities:</h2>
          <p> If you are purchasing any Insurance Policy or Product through our Portal, before purchasing it, you must read the terms and conditions of the relevant Insurer who’s Insurance Policy or Product you are purchasing. This User Agreement only governs your use of the Portal and does not cover the terms and conditions of the Insurance Policy or Product of the Insurer.</p>
          <p> Once you provide us with all the necessary information as requested on the Portal through your User Account, based on such information, we will display Insurance Policies with their pricing, coverage etc. we receive from participating Insurers. We take no responsibility of the accuracy and reliability of all information received from the Users and from the Insurers as displayed on the Portal. Your decision to choose a particular Insurer’s Policy or Product shall be solely yours and shall not be only based on the information displayed on the Portal. It is your responsibility to read and understand the Insurer’s Policy terms and conditions as provided by the Insurer, before you purchase a Policy or Product.</p>
          <p> Each User represents, warrants and agrees that:</p>
          <ul>
            <li>All information submitted by them to inxurehub whether during the registration process or thereafter shall be true, current, complete and accurate and shall promptly update any change in the said information;</li>
            <li> they have complete power and authority to accept these Terms and the terms and conditions of an Insurer regarding an Insurance Policy or Product they purchase, and have power and authority to perform the obligations stated; </li>
            <li> they consent to the inclusion of the information about them, whether personal or otherwise, in our User database and authorize inxurehub and our affiliates to share such information with the Insurers or otherwise use their information in accordance with the Privacy Policy. </li>
          </ul>
          <p> Each User further represents, warrants and agrees that they shall:</p>
          <ul>
            <li> carry on their activities on the Portal in compliance with any applicable laws and regulations; </li>
            <li> conduct their transactions through our Portal in good faith; </li>
            <li> carry on their activities in accordance with the Terms and any applicable additional terms and conditions of Insurers; </li>
            <li> not use the Services or Portal to defraud any person or entity (including without limitation sale of stolen items, use of stolen credit/debit cards); </li>
            <li> not impersonate any person or entity, misrepresent themselves or their affiliation with any person or entity; </li>
            <li> not engage in spamming or phishing; </li>
            <li> not engage in any other unlawful activities (including without limitation those which would constitute a criminal offence, give rise to civil liability, etc.) or encourage or abet any unlawful activities; </li>
            <li> not involve in any attempts to copy, reproduce, exploit or expropriate inxurehub’s various proprietary directories, databases and listings; </li>
            <li> not involve any computer viruses or other destructive or interruptive devices and codes that have the effect of damaging, interfering with, intercepting or expropriating any software or hardware system, data or personal information; </li>
            <li> not involve in any scheme to undermine the integrity of the data, systems or networks used by inxurehub and/or any User of the Portal or gain unauthorized access to such data, systems or networks; </li>
            <li> not engage in any activities that would otherwise create any liability for inxurehub or our affiliates. </li>
            <li> not use the Services and User ID to engage themselves in activities which are identical or similar to inxurehub’s business model. </li>
        </ul>
        <h2> 3. Permission to Use the Site</h2>
        <p> Content provided on this Portal is solely for informational purposes. Insurance Products, Policies, and information including price, features, coverage and any other details as expressed on this Portal are the responsibility of the Insurers displaying them and is not guaranteed as completely accurate by us.</p>
        <p> We grant you a limited permission to access and make personal use of this Portal, but not to download or modify the Portal or any portion of it in any manner. This permission to use and access the Portal does not include any resale or commercial use of this Portal or its contents; any collection and use of any Policy or Product listings, descriptions, or prices; any derivative use of this Portal or its contents; any downloading or copying of account information for the benefit of another User or a third party; or any use of data mining, robots, or similar data gathering and extraction tools.</p>
        <p> This Portal or any portion of it (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent by us as may be applicable.</p>
        <p> You agree and undertake not to perform restricted activities listed within this section; undertaking these activities will result in an immediate cancellation of User Account, Services, reviews, or any existing incomplete transactions and in severe cases may also result in legal action on:</p>
        <ul>
            <li> refusal to comply with the Terms described herein or any other guidelines and policies related to the use of the Portal as available on the Portal at all times; </li>
            <li> impersonate any person or entity or to falsely state or otherwise misrepresent your affiliation with any person or entity; </li>
            <li> use the Portal for illegal purposes; </li>
            <li> attempt to gain unauthorized access to or otherwise interfere or disrupt other computer systems or networks connected to the Portal or Services; </li>
            <li> interfere with another’s utilization and enjoyment of the Portal; </li>
            <li> post, promote or transmit through the Portal any prohibited materials as deemed illegal by the Islamic Republic of Pakistan; and/or </li>
            <li> use or upload, in any way, any software or material that contains, or which you have reason to suspect that contains, viruses, damaging components, malicious code or harmful components which may impair or corrupt the Portal’s data or damage or interfere with the operation of another User’s computer or mobile device or the Portal and use the Portal other than in conformance with the acceptable use policies of any connected computer networks, any applicable internet standards and any other applicable laws. </li>
        </ul>
        <p> You agree not to undertake any action to undermine the integrity of the computer systems or networks of inxurehub and/or any other User nor to gain unauthorized access to such computer systems or networks.</p>
        <h2> 4. Breaches by Users</h2>
        <p> Without limiting the generality of the provisions of the Terms, a User would be considered as being in breach of the Terms in any of the following circumstances:</p>
        <ul>
            <li> upon complaint or claim from any Insurer, inxurehub has reasonable grounds to believe that such User has willfully or materially failed to perform their obligations under their contract with such Insurer; </li>
            <li> inxurehub has reasonable grounds to suspect that such User has used a stolen debit or credit card or other false or misleading information in any transaction with an Insurer, </li>
            <li> inxurehub has reasonable grounds to suspect that any information provided by the User is not current or complete or is untrue, inaccurate, or misleading, or </li>
            <li> inxurehub believes that the User’s actions may cause financial loss or legal liability to inxurehub or their affiliates. </li>
        </ul>
        <p> inxurehub reserves the right to cooperate fully with governmental authorities, private investigators and/or injured third parties in the investigation of any suspected criminal, cyber or civil wrongdoing. Further, to the extent permitted by applicable laws and policies, inxurehub may disclose the User’s identity, contact information and/or information regarding the User’s Account(s), transactions or activities carried out on or via the Portal, if requested by a government or law enforcement body, an injured third party, or as a result of a or other legal action. inxurehub shall not be liable for damages or results arising from such disclosure, and Users agree not to bring any action or claim against inxurehub for such disclosure.</p>
        <h2> B. PURCHASES BY USERS </h2>
        <p> Through the Portal, inxurehub provides electronic web-based services for exchanging information between Insurers and Users for rendering of Services. However, for any Services, inxurehub does not represent either the User or the Insurer in specific transactions regarding any Insurance Policies or Products purchased by the Users. inxurehub does not control and is not liable to or responsible for the quality, safety, lawfulness or availability of the Insurance Products and Services offered for sale on the Portal or the ability of the Insurers to complete a sale or the ability of Users to complete a purchase.</p>
        <p> Each User acknowledges that it/he/she is fully assuming the risks of conducting any purchase and sale transactions in connection with using the Portal and Services, and that it is fully assuming the risks of liability or harm of any kind in connection with subsequent activity of any kind relating to Insurance Products and Services that are the subject of transactions using the Portal. Such risks shall include, but are not limited to, mis-representation of Insurance Products and Services, fraudulent schemes, unsatisfactory quality, failure to meet specifications, unlawful products, cost mis-calculations, breach of warranty, and breach of contract. All of the risks are hereafter referred to as "transaction risks". Each User agrees that inxurehub shall not be liable or responsible for any damages, claims, liabilities, costs, harms, inconveniences, business disruptions or expenditures of any kind that may arise a result of or in connection with any transaction risks.</p>
        <p> Users are solely responsible for all of the terms and conditions of the transactions conducted on, through or as a result of use of the Portal or Services.</p>
        <h2> 1. Conditions of purchase between the Users and Insurers</h2>
        <p> In case the User chooses to opt for an Insurance Product or Policy displayed on the Portal, the User, before purchasing any such Insurance Product or Policy, shall make sure that he/she/it has read the terms and conditions of the relevant Insurance Product or Policy, as issued by an Insurer. Once the User has read the terms and conditions of an Insurance Product or Policy, it may initiate the purchase process by clicking or tapping on “I AGREE” or “I ACCEPT” buttons, giving its irrevocable assent to such terms and shall be bound to abide by such terms and making an offer to purchase the Insurer’s Policy or Product. No Insurance Product or Policy displayed on the Portal shall be deemed as an offer made by inxurehub to sell the User any Insurance Product or Policy. When the Users submits their details and offer to purchase specific Insurance Product or Policy of an Insurer, the acceptance by an Insurer is independent from the inxurehub and no such acceptance shall be deemed to be an acceptance by inxurehub. The offer and acceptance stipulated in this section is independent of this User Agreement and depends solely on the terms and conditions of the Insurer. Once an offer is accepted by an Insurer, the User shall be notified of such acceptance through the Portal or any such mode as determined by the Insurer. Without the evidence of offer and acceptance, no Insurance Policy or Product sale shall be deemed to be completed and the insurance contract between the User and Insurer shall be considered null and void.</p>
        <h2> 2. Payments</h2>
        <p> All the prices or premiums or any other details of Insurance Products and Policy displayed on the Portal are provided independently by the Insurers and not by inxurehub. All the payments of purchases made by Users with respect to a specific Insurance Product or Policy shall be made through a payment gateway present on our Portal. Such payments shall be charged through your credit or debit cards provided by you while registering on our Portal. Other modes of payments include cheques, pay orders and bank transfer etc.</p>
        <h2> 3. Documents</h2>
        <p> The Users may be asked to upload certain documents, when they avail any Services on our Portal and assent to buy an Insurance Product or Policy. All such documents shall be uploaded as required to the User Account. All other documents in relation to the Insurance Policies and Products may be uploaded at the User Portal or may be made available to the respective User by sending a hard copy of such documents as determined by us or the Insurer.</p>
        <h2> 4. Insurance Products and Policies </h2>
        <p> All Insurance Products and Polices shall be governed by certain terms and conditions which the User is under an obligation to comply with. In case the offer made by a User is accepted by an Insurer an underlying acceptance and relevant terms and conditions of an Insurer’s Insurance Product or Policy shall be shared by the Insurer to the User. The User shall be obligated to read it carefully and familiarize itself/himself/herself with such terms of such Policy or Product. Such terms may include terms related to deductibles as to premiums, limits of insurance cover, and other obligations that apply. In case there is an ambiguity to understand the terms of such policy, the Insurer may be contacted by the User for clearance of thought and further guidance.</p>
        <p> In case the User fails to read and comply with the Insurer’s terms and suffers loses in respect to its Insurance Product or Policy or any other loses, inxurehub disclaims any liability arisen in such circumstances.</p>
        <p> In the event that any User has a dispute with any Insurer to a transaction, such User agrees to release and indemnify inxurehub (and their agents, affiliates, directors, officers and employees) from all claims, demands, actions, proceedings, costs, expenses and damages (including without limitation any actual, special, incidental or consequential damages) arising out of or in connection with such transaction.</p>
        <u> C. REPRESENTATIONS AND WARRANTIES</u>
        <p> We do not make or give any representation or warranty, respectively as to specifics (such as quality, value, salability, etc.) of any Insurance Product or Policy displayed or to be sold or sold on the Portal. We accept no liability for any errors, omissions, or any actions whether on behalf of itself, our affiliates or the Insurer.</p>
        <p> We are not responsible for any non-performance or breach by either party of any contract entered into between you and any Insurer. We cannot and do not guarantee your actions or those of the Insurers as they conclude transactions on the Portal.</p>
        <p> We do not at any point of time during any transaction as entered into by you with a third party on our Portal, gain title to or have any rights or claims over the Insurance Products or Policies offered by an Insurer. Therefore, we do not have any obligations or liabilities in respect of such contract(s) entered into between you and any Insurer. We are also not responsible for unsatisfactory or delayed performance by Insurers.</p>
        <u> D. DISCLAIMERS AND LIABILITIES</u>
        <p> Please review our Privacy Policy, which also governs User’s visit to the Portal. The personal information / data provided to us by you or your use of our Portal will be treated as strictly confidential, in accordance with the privacy agreement. If you object to your information being transferred or used in the manner specified in the privacy agreement, please do not use the Portal.</p>
        <h2> 1. Site only for Communication</h2>
        <p> You agree, understand and acknowledge that the Portal enables you to purchase Insurance Products or Policies displayed at the price indicated therein at any time from any location using a payment method as made available on the Portal. You further agree and acknowledge that we are only a facilitator and cannot be a party to or control in any manner any transactions on the Portal or on a payment gateway as made available to you on the Portal. Accordingly, the contract of sale of Insurance Products and Policies on the Portal shall be a strictly bipartite contract between you and the Insurers on our Portal.</p>
        <h2> 2. Continued Availability of the Site</h2>
        <p> We will do our utmost to ensure that access to the Site is consistently available and is uninterrupted and error-free. However, due to the nature of the internet and the nature of the Portal, this cannot be guaranteed. Additionally, your access to the Portal may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or Services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.</p>
        <h2> 3. Insurance Claims Handling</h2>
        <p> As we act only as corporate agents of various Insurers, we disclaim any liability with regards to any claims regarding the Insurance Products or Policies. We act only as facilitators between the Users and Insurers, therefore, if any User has a claim regarding an Insurance Product, the same shall be communicated to the respective Insurer. The Insurer shall make the claim settlement directly in the name of the User, its/his/her representative or guardian, as the case may be.</p>
        <h2> 4. Disclaimer of Liability </h2>
        <p> You acknowledge and undertake that you are accessing the Services on the Portal and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through the Portal. We shall neither be liable nor responsible for any actions or inactions of Insurers nor any breach of conditions, representations or warranties by the Insurers and hereby expressly disclaim any and all responsibility and liability in that regard. We shall not mediate or resolve any dispute or disagreement between you and the Insurers.</p>
        <h2> 5. Limitation of Liability</h2>
        <p> You agree that neither inxurehub nor its employees, directors and personnel shall be liable for any direct, indirect, incidental, special, consequential or exemplary damages arising out of or in connection with (i) your use of the Portal, (ii) the liability of any Insurer, and (iii) in connection with the Services availed through the Portal. You further agree that inxurehub shall not be liable for any damages arising from interruption, suspension or termination of Services, including without limitation any direct, indirect, incidental, special, consequential or exemplary damages, whether such interruption, suspension or termination was justified or not, negligent or intentional, inadvertent or advertent.</p>
        <h2> 6. Indemnity </h2>
        <p> You agree to defend, indemnify and hold harmless inxurehub and its officers, directors, employees, agents and affiliates (each, an “Indemnified Party”), from and against any losses, claims, actions, costs, damages, penalties, fines and expenses, including without limitation attorneys' fees and expenses, that may be incurred by an Indemnified Party arising out of, relating to or resulting from your unauthorized use of the Portal or from any breach by you of these Terms, including without limitation any actual or alleged violation of any federal, provincial or local statute, ordinance, administrative order, rule or regulation. inxurehub shall provide notice to you promptly of any such claim, suit or proceeding and shall have the right to control the defense of such action, at your expense, in defending any such claim, suit or proceeding. You agree that this clause shall survive termination of this agreement.</p>
        <u> E. TRADEMARKS AND COPYRIGHTS </u>
        <p> inxurehub.com, inxurehub logo, inxurehub, and other marks indicated on our Portal are trademarks or registered trademarks in the relevant jurisdiction(s). Our graphics, logos, page headers, button icons, scripts and service names are the trademarks or trade dress and may not be used in connection with any service that does not belong to us or in any manner that is likely to cause confusion among Users, or in any manner that disparages or discredits us. All other trademarks that appear on this Portal are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by us.</p>
        <p> All intellectual property rights, whether registered or unregistered, in the Portal, information content on the Portal and all the website design, including, but not limited to text, graphics, software, photos, video, music, sound, and their selection and arrangement, and all software compilations, underlying source code and software shall remain our property. The entire contents of the Portal also are protected by copyright as a collective work under Pakistani copyright laws and international conventions. All rights are reserved.</p>
        <p> You may not, and will not encourage, assist or authorize any other person to copy, modify, reverse engineer, decompile or disassemble, or otherwise tamper with our Portal and its contents whether in whole or in part, or create any derivative works from it.</p>
        <u> F. GENERAL PROVISIONS</u>
        <h2> 1. Communicating with Us</h2>
        <p> When you visit the Portal, or send e-mails to us, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, short messaging service, and phone call or by posting notices on the Portal or by any other mode of communication we choose to employ. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the Portal (and/or placement of your order) and agree to treat all modes of communication with the same importance.</p>
        <h2> 2. Amendments </h2>
        <p> We reserve the right to make changes to the Portal, its policies, these Terms and any other publicly displayed condition or Service promised at any time. You will be subject to the policies and Terms in force at the time you used the Portal unless any change to those policies or these Terms is required to be made by law or government authority.</p>
        <p> inxurehub reserves the right, at its sole and absolute discretion, to change, modify, add to, supplement or delete any of the terms and conditions of these Terms, effective immediately without prior notice. Your continued use of the Portal following any revision to these Terms shall constitute your complete and irrevocable acceptance of any and all such changes. Contact us if you have any comments or questions, please do not hesitate to reach out to us at info@inxurehub.com.</p>
        <h2> 3. Events Beyond Our Control</h2>
        <p> We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause or reason which is beyond our reasonable control.</p>
        <p> Under no circumstances shall inxurehub be held liable for any delay or failure or disruption of the content or Services delivered through the Portal resulting directly or indirectly from acts of nature, forces or causes beyond our reasonable control, including without limitation, internet failures, computer, telecommunications or any other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, flood, storms, explosions, acts of god, war, governmental actions, orders of domestic or foreign courts or tribunals or non-performance of third parties.</p>
        <h2> 4. Termination </h2>
        <p> In an event of a breach by you of these Terms, in addition to any other legal or equitable remedies, we may, without prior notice to you, immediately revoke any or all of your rights granted under these Terms. Upon such termination, you shall immediately cease all access to and use of the Portal and we shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to you and deny your access to and use of this Portal in whole or in part. Any termination of this Agreement shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination. You furthermore agree that the inxurehub shall not be liable to you or to any other person as a result of any such suspension or termination. If you are dissatisfied with the Portal or with any terms, conditions, rules, policies, guidelines, or practices in operating the Portal, your sole and exclusive remedy is to discontinue using the Portal and its Services.</p>
        <h2> 5. Governing law and jurisdiction</h2>
        <p> These Terms are governed by the laws of Pakistan. The Pakistani courts in Lahore shall have the exclusive jurisdiction and venue over any dispute arising out of or relating to these Terms, and each party hereby consents to the jurisdiction and venue of such courts. If any provision of these Terms is found to be invalid in any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect.

</p> 
      </div>
  </section>
  </template>
  