<template>
  <div class="banner">
      <img src="../assets/images/privacy-policy-banner.jpg" class="w-100" />
  </div>

<section class="content-page">
	<div class="container">
		<h1> Privacy Policy </h1>
		<p> inxurehub.com also hereby known as “we”, “us” “they” “our” or “Inxurehub” or “Inxurehub (Private) Limited” has developed this privacy policy (“Privacy Policy”) to explain how we collect, use, and disclose information about you to the Insurers when you use the Portal and the services rendered by us through the Portal.</p>
		<p> All visitors/users (“Customer”/ “Customers”, “Users” / “User”, “You “or “Your”) of the Portal are advised to read and understand our Privacy Policy carefully, as by accessing the Portal, you agree to be bound by the terms and conditions of this Privacy Policy and consent to the collection, storage and use of information relating to you as provided herein.</p>
		<p> By using this Portal, you consent to allowing us to collect and use your personal information as described in this Privacy Policy. By using the portal, you agree to the contents of this Privacy Policy and give your irrevocable consent to us for collecting your personal information.</p>
		<p> Our Privacy Policy explains:</p>
		<ul>
			<li> What information we collect and why we collect it. </li>
			<li> How we use that information. </li>
			<li> The choices we offer, including how to access and update information. </li>
		</ul>
		<p> We have tried to keep it as simple as possible, but if you’re not familiar with terms like cookies, IP addresses, pixel tags and browsers, then kindly understand these key terms first. Your privacy matters to us so please do take the time to get to know our practices – and if you have, any questions feel free to contact us by sending an email to info@insurehub.com.pk</p>
		<h2> A. COLLECTION OF INFORMATION </h2>
		<p> To provide the best experience to you, we collect and store personal information provided by you from time to time on the Portal. When you contact us, we keep a record of your communication to help solve any issues you might be facing.The personal information that we may collect about you broadly falls into the following categories:</p>
		<u> Information you provide to us </u>

		<p> When you register on our Portal: </p>
		<ul>
			<li> if you are an individual, you will be asked to provide certain contact information that is necessary for the registering for a Portal account, including name, address, phone number, email address, job title and department; </li>
			<li> if you are a company, you will be asked to provide certain identity information, tax registration and/or related information about your business, such as your company name, business type and industry, corporate registration details and information about your business license; </li>
			<li> we will also ask you to provide details or preferences about the Insurance Products or Policies that you may be interested in buying via the Portal and any details or preferences relevant to your purchases; </li>
			<li> in connection with the facilitation of transactions, purchases and payments over the Portal, you will be asked to provide certain payment information. This may include bank account numbers, billing and delivery information, credit/debit card numbers, expiration dates and security code and tracking information from cheques or money orders to facilitate the rendering of services to you. </li>
			<li> we will process any information provided to us in connection with facilitating your claims and complaints in connection with the Portal or an Insurance Product or Policy of an Insurer purchased via the Portal. Please note, Users may submit complaints regarding the services or an Insurance Product or Policy on the Portal and/or disputes between the Insurers and the Customers to the Portal and under such circumstances, we will process data provided to us by the Customers that is relevant to the disputes or complaints submitted. </li>
		</ul>

		<u> Information that we collect automatically</u>
		<ul>
			<li> If you contact us via our customer services department i.e. chat service, telephone or email, we may record a copy of your correspondence and may ask for additional information to verify your identity. </li>
			<li> We may collect contact details and other information of Users from publicly available sources for the purposes of verifying the Users and performing due diligence on the Users. </li>
			<li> We may collect details of the User activities, transactions and interactions on the Portal including information relating to the types and specifications of the Insurance Policies and Products purchased, pricing information, claims and complaint records, communications between Insurers and Users and any information disclosed in any discussion forum. </li>
			<li> From time to time, we may also collect information, including contact names, addresses, phone numbers, fax numbers and email addresses, about the prospective Users, during or in connection with marketing of Insurance Products or Policies through our Portal or other means. </li>
			<li> If you visit our Portal, we will automatically collect certain information from your device. Specifically this information may include IP addresses, device type, unique device identification numbers, browser type, broad geographic location (e.g. country or city-level location), browsing patterns and details of how you have interacted with our Portal and the services available on it. In addition, we gather statistical information about the Portal and visitors to the Portal including, browser software, operating system, software and hardware attributes, pages viewed, and number of sessions. Collecting this information enables us to better understand the visitors who come to our Portal, where they come from, and what content is of interest to them. We use this information for our internal analytics purposes and to improve the quality and standard of our Portal. Some of this information may be collected using cookies and similar tracking technology, as explained further under the section D “COOKIES” below. </li>
			<li> We may receive personal information about you from third parties that are engaged by us to assist with providing services. This may include your name, email address, company details and contact information. </li>
		</ul>

		<h2> B. DISCLOSURE OR SHARING OF PERSONAL INFORMATION </h2>

		<p> We may disclose (or provide access to) personal information to the following categories of recipients:</p>
		<ul>
			<li> third party business partners, service providers and/or affiliates of inxurehub.com engaged by us or working with us to assist us to provide services to you or who otherwise process personal information for purposes described in this Privacy Policy or notified to you when we collect your personal information. Categories of these partners or service providers include: </li>
			<ol>
				<li> members of Inxurehub and their respective affiliates and/or their designated service providers that work with us to provide processing services such as software, tools, systems and messaging services for purposes described in this Privacy Policy; </li>
				<li> marketing and advertising Portals, such as Google, Twitter, Facebook and Instagram, and providers of analytics services relating to users’ behavior, in order to tailor the content you see when visiting our Portal. These Portals may combine information they collect on our Portal with data on their Portals and data they collect from other websites or through other sources in order to conduct targeted advertising. The processing activities of these third party marketing Portals are governed by their own privacy policies, not this Privacy Policy; </li>
				<li> cloud computing service providers to provide cloud storage services; </li>
				<li> customer service providers to provide after-sale services; </li>
				<li> risk control service providers to assess the security of Users’ accounts and transaction risks; and </li>
				<li> third party rating / reviewing service providers to carry out reviews of our Services with customers if you choose to participate in reviewing or rating our Services; </li>
			</ol>
			<li> to our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations where we believe it is necessary to comply with applicable laws or to exercise, establish or defend our legal rights or protect your vital interests or those of any other person, we may also disclose and transfer your personal information or as otherwise required or permitted by applicable laws. </li>
			<li> to an actual or potential buyer (its agents or advisers) in connection with any actual or proposed purchase, merger or acquisition of any part of our business, provided that we inform the buyer it must use your personal information only for the purposes disclosed in this Privacy Policy. </li>
			<li> to the participating Insurers and agents and such other individuals and entities as deemed necessary. </li>
			<li> to any other person with your consent to the disclosure. </li>
			<li> we may provide aggregated or anonymized data to third parties, but when we do so, the information we share is in a de-identified format that does not personally identify you. </li>

		</ul>
		<h2> C. RETENTION </h2>
		<p> We may retain your personal information as long as we have an ongoing legitimate business need to do so, for example, to provide services to you, or as required or permitted by applicable laws, such as tax and accounting laws.</p>
		<p> When we have no ongoing legitimate business need to process your personal information, we may either delete or anonymize it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
		<h2> D. COOKIES </h2> 
		<p> This section explains how we use cookies and similar technologies (we'll refer to them collectively as "cookies") to recognize you when you visit our Portal.</p>
		<u> Why we use cookies?</u>
		<p> We use first and third party cookies for several reasons. Some cookies are required for technical and/or security reasons in order for our Portal to operate. We refer to these as "essential" or "strictly necessary" cookies. First party cookies are mainly served to enable basic functionality on our Portal; including web analytics cookies that help us understand how Users use our web pages and how we can improve their use.</p>
		<p> In addition we also use social media cookies. You may register for Portal membership and "share" Portal content through social media such as Facebook and Twitter. These websites have their own privacy policies which govern their use of information, which you can find on the respective websites.</p>
		<p> We also use cookies to enable us to track and target the interests of Portal visitors to enhance the experience on our Portal by researching visiting patterns and conduct interest-based advertising; assisting our partners to track user visits to the Portals; and to track progress and participation in promotions. We use first and third party cookies for these purposes.</p>
		<h2> E. CHANGES TO THIS PRIVACY POLICY </h2>
		<p> We may update this Privacy Policy from time to time in response to changing legal, technical or business developments. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Policy changes if and when this is required by applicable data protection laws.</p>
		<p> Any changes to this Privacy Policy will be posted by us on the Portal. Once posted on the Portal, the new Privacy Policy will be effective immediately.</p>
		<u> Legal basis for data processing</u>
		<p> We process personal information for the purposes set out in this Privacy Policy, as described above. Our legal basis for processing personal information will depend on the personal information concerned and the specific context in which we collect it. However, we will normally collect and process personal information about you only where it is:</p>
		<ul>
			<li> necessary for providing the services pursuant to the agreement between you and inxurehub.com and/or its affiliates (for example, to provide you with the Services you request and to identify and authenticate you so you may use the Portal);</li>
			<li> necessary to comply with legal requirements (for example, to comply with applicable accounting rules and to make mandatory disclosures to law enforcement) </li>
			<li> necessary for our legitimate interests and not overridden by your rights; and/or </li>
			<li> where it is based on your consent. </li>
		</ul>
		<p> If we collect and use your personal information in reliance on our legitimate, this interest will normally be to operate our Portal and services, manage our relationship with you and communicate with you as necessary to provide our services to you and for our legitimate commercial interest, for instance, when responding to your queries, improving our Portal and our services, undertaking marketing, or for the purposes of ensuring the security of our Portal and services and detecting or preventing illegal activities such as fraud. We may have other legitimate interests and if appropriate we will make clear to you at the relevant time what those legitimate interests are.</p>
	</div>
</section>
</template>
