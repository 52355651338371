import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as bootstrap from 'bootstrap'
import axios from 'axios'
import VueTheMask from 'vue-the-mask'
import VueAxios from 'vue-axios'
import AOS from "aos";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


import './assets/sass/inxurehub.scss'
import 'vue-multiselect/dist/vue-multiselect.css'

const options = {
    confirmButtonColor: '#F34653',
    cancelButtonColor: '#ff7674',
};

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueTheMask)
app.use(bootstrap)
app.use(VueAxios, axios)
library.add(fas, fab)
app.component('fa-icon', FontAwesomeIcon)
app.use(VueSweetalert2, options);
app.mount('#app')