<template>
    <div>
        <div class="myarea-header">
            <div class="container">
                <h1 class="myarea-heading"><img src="../assets/images/icons/arrow-shape-icon.png" alt=""> <span v-if="serviceName == 'PA'"> Personal Accident </span> <span v-else> {{serviceName}} Insurance </span>
                </h1>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <!-- {{ activeItem }} --- {{ formSelectedFieldsValues }} -->
                    <div class="form-submitted" v-if="congratulations">
                        <div class="js-container con-container" style="top:0px !important;"></div>
                            <div>
                                <div class="checkmark-circle">
                                <div class="background"></div>
                                <div class="checkmark draw"></div>
                                </div>
                                <h1>Congratulations!</h1>
                                <p>Your form is submitted successfully!</p>
                            </div>  
                    </div>
                    <div v-if="!congratulations" class="feature-form-main">

                        <div class="row">
                            <div class="offset-md-3 col-md-9">
                                <span class="active-feature-head"> <img :src="serviceLogo" /> {{subClassName}} </span>
                            </div>
                        </div>
                        
                        <div class="row mb-4">
                            <div class="col-md-9 offset-md-3">
                                <button class="btn btn-primary me-2 mb-2" v-for="(single,index) in subClassesList" @click="getSingleId(single)">
                                    {{ single.name }}
                                </button>

                                <div class="col mt-3">
                                    <button class="btn btn-primary me-2 mb-2" v-for="(value,index) in subClassInnerCategories" @click="getSingleId(value)">
                                        {{ value.name }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-3">
                                <div class="nav flex-column nav-pills mt-3 p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button class="tab-link" disabled @click.prevent="setActive('personelDetail')" :class="{ active: isActive('personelDetail') }">Personel Details</button>
                                    <button class="tab-link" disabled @click.prevent="setActive('package')" :class="{ active: isActive('package') }">Choose Package</button>
                                    <button class="tab-link" disabled @click.prevent="setActive('upload-info')" :class="{ active: isActive('upload-info') }">Upload information & Documents</button>
                                    <button class="tab-link" disabled @click.prevent="setActive('summary')" :class="{ active: isActive('summary') }">Summary</button>
                                    <button class="tab-link" disabled @click.prevent="setActive('payment')" :class="{ active: isActive('payment') }" >Make Payment</button>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="tab-content" id="v-pills-tabContent">
                                    <div class="loader" v-if="loader"></div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('personelDetail') }" id="personelDetail">
                                        <div class="container">
                                            <div class="row get-product-form">
                                                <div v-for="(value,key) in subClassForm" :key="key" class="col-md-6 mb-4" >
                                                    <select :id="value.field_name" v-if="value.type == 'dropDown' && value.field_name !== 'departre_airport'"  
                                                        @change="changeChild(value.child_id,$event)"  class="form-control"
                                                        :required="value.required == 1 ? true : false"
                                                    >
                                                        <option selected> {{ value.label }} </option>
                                                        <option :value="options.id" :data-id="options.form_field_id" v-for="(options , jKey) in value.defaultvaluesparent"> {{options.text}}</option>
                                                    </select>
                                                    <select :id="value.field_name" v-else-if="value.type == 'dropDown' && value.field_name == 'departre_airport'"  
                                                        @change="changeChild(value.child_id,$event)"  class="form-control"
                                                        :required="value.required == 1 ? true : false"
                                                    >
                                                        <option> {{ value.label }} </option>
                                                        <option value="5354">Islamabad (ISB)</option>
                                                        <option value="5355">Karachi (KHI)</option>
                                                        <option value="5356">Lahore (LHE)</option>
                                                        <option value="5360">Multan (MUX)</option>
                                                        <option value="5361">Peshawar (PEW)</option>
                                                        <template v-for="(options , jKey) in value.default_values">
                                                            <option :value="options.id" :data-id="options.form_fields_id" v-if="options.text !== 'Islamabad (ISB)' && options.text !== 'Karachi (KHI)' && options.text !== 'Lahore (LHE)' && options.text !== 'Multan (MUX)' && options.text !== 'Peshawar (PEW)'">
                                                                {{options.text}}
                                                            </option>
                                                        </template>
                                                    </select>
                                                    <!-- <select :id="value.field_name" @change="setAdditionFormFieldValues($event)" v-else-if="value.field_name == 'nationality'"  class="form-control">
                                                        <option> {{ value.label }} </option>
                                                        <option data-id="408" value="6072">Pakistan</option>
                                                        <template v-for="(options , jKey) in value.default_values">
                                                            <option :value="options.id" :data-id="options.form_fields_id" v-if="options.text !== 'Pakistan'">
                                                                {{options.text}}
                                                            </option>
                                                        </template>
                                                    </select> -->
                                                    <input :id="value.field_name" @change="setFormFieldValuesObject($event)" v-else-if="value.type === 'date' && (value.field_name === 'date_of_birth' || value.field_name === 'date_of_birtth')" :max="maximumDate" :type="value.inputType" :value="value.label" onfocus="(this.type='date')" onblur="(this.type='text')" :placeholder="value.label" class="form-control" />
                                                    <input :id="value.field_name" @change="setFormFieldValuesObject($event)" v-else-if="value.type === 'date'" :type="value.inputType" :value="value.label" onfocus="(this.type='date')" onblur="(this.type='text')" :placeholder="value.label" class="form-control" />
                                                    <div v-else-if="value.type == 'radioButton'">
                                                        <label class="label">{{ value.label }}</label>
                                                        <div class="form-group m-0">
                                                            <label class="radio-holder" v-for="option in value.defaultvaluesparent" :for="option.text" :key="option.field_id">
                                                                <input type="radio" :id="option.text" :name="value.field_name" :value="option.text" @change="setFormRadio($event)" >
                                                                <span class="checkmark"></span>
                                                                {{ option.text }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <!-- <input :id="value.field_name" @change="setFormFieldValuesObject($event)" v-else-if="value.field_name == 'departure_date'" :type="value.inputType" :value="value.label" onfocus="(this.type='date')" onblur="(this.type='text')" :placeholder="value.label" class="form-control" /> -->
                                                    <!-- <input v-mask="'##,###,######'" :id="value.field_name" @change="setFormFieldValuesObject($event)" v-else-if="value.field_name == 'vehicle_value'" :value="value.label" :placeholder="value.label" class="form-control" /> -->
                                                    <input :id="value.field_name" @keyup="setFormFieldValuesObject($event)" v-else :type="value.inputType" :placeholder="value.label" class="form-control" />
                                                    <span class="text-danger" v-if="finalValidationsFields[value.field_name]">{{finalValidationsFields[value.field_name][0]}}</span>
                                                    <span class="text-danger" v-if="finalValidationsFields[value.field_name+'.0']">{{finalValidationsFields[value.field_name+'.0'][0]}}</span>
                                                </div>
                                                <!-- {{ validations }} -->
                                            </div>
                                            <div class="row">
                                                <div class="col align-right">
                                                    <button type="submit" @click.prevent="getProductPlanList" class="btn btn-primary">Next <img src="../assets/images/icons/arrow-up-right.png" alt=""> </button>
                                                </div>
                                            </div>
                                       </div>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('package') }" id="package">
                                        <div class="pakages-main" v-if="!showDetailsBox">
                                            <div v-for="(value,key) in productPlans" :key="key" class="package-box" @click="showDetails(value)">
                                                <div class="package-detail-box clearfix">
                                                    <span class="package-logo"><img :src="value.company_logo" :alt="value.company_name" /> </span>
                                                    <div class="package-detail-text">
                                                        <strong class="title"> {{ value.company_name }} </strong>
                                                        <span v-if="value?.plan_name" class="title-dot"> {{ value?.plan_name }} </span>
                                                        <span v-else class="title-dot"> {{ value?.product_name }} </span>
                                                    </div>
                                                    <div class="package-sum">
                                                        <ul>
                                                            <li>
                                                                <span class="sum-title"> Validity </span>
                                                                <span class="sum-value"> {{ value.validity }} </span>
                                                            </li>
                                                            <li>
                                                                <span v-if="value.add_ons" class="sum-title"> Cover Limit </span>
                                                                <span v-if="value.add_ons" class="sum-value"> {{ (value?.sum_insured_value).toLocaleString('en-US') }} </span>
                                                            </li>
                                                            <!-- <li>
                                                                <span class="stat-title"> Cover Limit </span>
                                                                <span class="stat-number">  {{ currentSelectedPlan.covers[0]?.currency_code }}  {{ (currentSelectedPlan?.sum_insured_value).toLocaleString('en-US') }} </span>
                                                            </li> -->
                                                            <li v-if="serviceName != 'Health'">
                                                                <span class="sum-title"> Rate </span>
                                                                <span class="sum-value"> {{ value.net_premium_rate.toFixed(2) }} % </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="package-box-foot clearfix">
                                                    <button class="btn btn-primary me-2"> Buy Now </button>
                                                    <!-- <button class="btn btn-primary"> Compare </button> -->
                                                    <span class="package-grand"> Premimum Amount: <strong> {{ value.sum_insured_currency }} {{ (value.price).toLocaleString('en-US') }} </strong> </span>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="package-detail-section" v-if="showDetailsBox">
                                            <div class="package-box">
                                                <div class="package-detail-box clearfix">
                                                    <span class="package-logo"> <img :src="currentSelectedPlan.company_logo" :alt="currentSelectedPlan.company_name" /> </span>
                                                    <div class="package-detail-text">
                                                        <strong class="title"> {{currentSelectedPlan.company_name}} </strong>
                                                        <span v-if="currentSelectedPlan?.plan_name" class="title-dot"> {{ currentSelectedPlan?.plan_name }} </span>
                                                        <span v-else class="title-dot"> {{ currentSelectedPlan?.product_name }} </span>                                                        
                                                    </div>
                                                    <div class="sum-total-package">
                                                        <span class="total-head"> Total </span>
                                                        <span class="total-head-grand"> {{ currentSelectedPlan?.sum_insured_currency }}  {{ (currentSelectedPlan?.sum_insured_value).toLocaleString('en-US') }} </span>
                                                    </div>
                                                </div>
                                                <div class="package-box-foot clearfix">
                                                    <ul class="package-detail-stat">
                                                        <li>
                                                            <span class="stat-title"> Premium Amount </span>
                                                            <span class="stat-number">  {{ currentSelectedPlan?.sum_insured_currency }}  {{ (currentSelectedPlan?.price).toLocaleString('en-US') }} </span>
                                                        </li>
                                                        <li style="display:none">
                                                            <span class="stat-title"> Tracker </span>
                                                            <span class="stat-number">  139,650 </span>
                                                        </li>
                                                        <li>
                                                            <span class="stat-title"> Cover Limit </span>
                                                            <span class="stat-number">  {{ currentSelectedPlan?.sum_insured_currency }}  {{ (currentSelectedPlan?.sum_insured_value).toLocaleString('en-US') }} </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul class="package-detail-extra-info">
                                                <li class="extra-info-list-item" v-if="currentSelectedPlan?.product_description && (currentSelectedPlan?.product_description !== '' || currentSelectedPlan?.product_description !== null)">
                                                    <div class="package-info-box" @click="packageExtraInfo('ProductDetail')">
                                                        <span class="icon-holder">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.56035 20.576C10.3003 21.4891 11.1703 21.9456 12.1062 21.9456V12.0348L2.82764 7.15137C2.81398 7.17332 2.80056 7.19549 2.78738 7.21787C2.19531 8.22333 2.19531 9.4745 2.19531 11.9768V12.0928C2.19531 14.5951 2.19531 15.8463 2.78738 16.8517C3.37945 17.8572 4.4457 18.4167 6.57818 19.5358L8.56035 20.576Z" fill="#1C274C"/>
                                                                <path opacity="0.7" d="M17.6366 4.53386L15.6544 3.49367C13.9144 2.58057 13.0445 2.12402 12.1086 2.12402C11.1727 2.12402 10.3028 2.58057 8.56279 3.49367L6.58062 4.53386C4.49561 5.62803 3.4299 6.18729 2.83008 7.15144L12.1086 12.0349L21.3871 7.15144C20.7873 6.18729 19.7216 5.62803 17.6366 4.53386Z" fill="#2F73FE"/>
                                                                <path d="M21.4262 7.21787C21.413 7.19549 21.3996 7.17332 21.3859 7.15137L12.1074 12.0348V21.9456C13.0433 21.9456 13.9133 21.4891 15.6532 20.576L17.6354 19.5358C19.7679 18.4167 20.8341 17.8572 21.4262 16.8517C22.0183 15.8463 22.0183 14.5951 22.0183 12.0928V11.9768C22.0183 9.4745 22.0183 8.22333 21.4262 7.21787Z" fill="#2F73FE"/>
                                                                <path d="M21.4262 7.21787C21.413 7.19549 21.3996 7.17332 21.3859 7.15137L12.1074 12.0348V21.9456C13.0433 21.9456 13.9133 21.4891 15.6532 20.576L17.6354 19.5358C19.7679 18.4167 20.8341 17.8572 21.4262 16.8517C22.0183 15.8463 22.0183 14.5951 22.0183 12.0928V11.9768C22.0183 9.4745 22.0183 8.22333 21.4262 7.21787Z" fill="url(#paint0_linear_3110_7647)"/>
                                                                <defs>
                                                                <linearGradient id="paint0_linear_3110_7647" x1="14.5929" y1="14.5485" x2="21.7223" y2="14.5897" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#2F73FE"/>
                                                                <stop offset="1" stop-color="#4FADF8"/>
                                                                </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span class="title"> Product Details </span>
                                                    </div>
                                                </li>
                                                <li class="extra-info-list-item" v-if="currentSelectedPlan?.things_to_note && (currentSelectedPlan?.things_to_note !== '' || currentSelectedPlan?.things_to_note !== null)">
                                                    <div class="package-info-box" @click="packageExtraInfo('TermsConditions')">
                                                        <span class="icon-holder">
                                                            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.52734 8.79189C3.52734 5.56715 3.52734 3.95477 4.52914 2.95297C5.53095 1.95117 7.14332 1.95117 10.3681 1.95117H12.0782C15.303 1.95117 16.9154 1.95117 17.9172 2.95297C18.919 3.95477 18.919 5.56715 18.919 8.79189V12.2123C18.919 15.437 18.919 17.0494 17.9172 18.0512C16.9154 19.053 15.303 19.053 12.0782 19.053H10.3681C7.14332 19.053 5.53095 19.053 4.52914 18.0512C3.52734 17.0494 3.52734 15.437 3.52734 12.2123V8.79189Z" fill="#2F73FE"/>
                                                                <path d="M3.52734 8.79189C3.52734 5.56715 3.52734 3.95477 4.52914 2.95297C5.53095 1.95117 7.14332 1.95117 10.3681 1.95117H12.0782C15.303 1.95117 16.9154 1.95117 17.9172 2.95297C18.919 3.95477 18.919 5.56715 18.919 8.79189V12.2123C18.919 15.437 18.919 17.0494 17.9172 18.0512C16.9154 19.053 15.303 19.053 12.0782 19.053H10.3681C7.14332 19.053 5.53095 19.053 4.52914 18.0512C3.52734 17.0494 3.52734 15.437 3.52734 12.2123V8.79189Z" fill="url(#paint0_linear_3110_7636)"/>
                                                                <path d="M15.0887 14.3515C15.2383 14.2348 15.374 14.0991 15.6455 13.8276L19.0294 10.4437C19.1112 10.3619 19.0738 10.221 18.9645 10.1831C18.565 10.0445 18.0454 9.78431 17.5594 9.29826C17.0733 8.81222 16.8132 8.29262 16.6746 7.89318C16.6367 7.78388 16.4958 7.74643 16.4139 7.82825L13.03 11.2122C12.7586 11.4836 12.6228 11.6193 12.5061 11.769C12.3684 11.9455 12.2504 12.1365 12.1541 12.3386C12.0724 12.5099 12.0117 12.692 11.8903 13.0562L11.7335 13.5268L11.4841 14.275L11.2502 14.9766C11.1905 15.1558 11.2371 15.3534 11.3707 15.487C11.5043 15.6205 11.7019 15.6672 11.8811 15.6074L12.5827 15.3736L13.3309 15.1242L13.8015 14.9673C14.1656 14.8459 14.3477 14.7852 14.519 14.7036C14.7211 14.6073 14.9121 14.4892 15.0887 14.3515Z" fill="#1C274C"/>
                                                                <path d="M20.0889 9.3842C20.8112 8.66196 20.8112 7.49097 20.0889 6.76873C19.3667 6.04649 18.1957 6.04649 17.4735 6.76873L17.3647 6.87752C17.2597 6.9825 17.2121 7.12914 17.2383 7.27527C17.2548 7.36719 17.2854 7.50159 17.3411 7.66196C17.4523 7.98267 17.6625 8.40367 18.0582 8.79943C18.454 9.1952 18.875 9.40532 19.1957 9.51659C19.3561 9.57223 19.4905 9.60281 19.5824 9.61931C19.7285 9.64554 19.8751 9.59797 19.9801 9.49299L20.0889 9.3842Z" fill="#1C274C"/>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.16211 7.93673C7.16211 7.58254 7.44924 7.29541 7.80343 7.29541H13.3615C13.7157 7.29541 14.0028 7.58254 14.0028 7.93673C14.0028 8.29092 13.7157 8.57805 13.3615 8.57805H7.80343C7.44924 8.57805 7.16211 8.29092 7.16211 7.93673ZM7.16211 11.3571C7.16211 11.0029 7.44924 10.7158 7.80343 10.7158H10.3687C10.7229 10.7158 11.01 11.0029 11.01 11.3571C11.01 11.7113 10.7229 11.9984 10.3687 11.9984H7.80343C7.44924 11.9984 7.16211 11.7113 7.16211 11.3571ZM7.16211 14.7775C7.16211 14.4233 7.44924 14.1361 7.80343 14.1361H9.08606C9.44025 14.1361 9.72738 14.4233 9.72738 14.7775C9.72738 15.1316 9.44025 15.4188 9.08606 15.4188H7.80343C7.44924 15.4188 7.16211 15.1316 7.16211 14.7775Z" fill="#2F73FE"/>
                                                                <defs>
                                                                <linearGradient id="paint0_linear_3110_7636" x1="7.38731" y1="10.5021" x2="18.459" y2="10.588" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#2F73FE"/>
                                                                <stop offset="1" stop-color="#4FADF8"/>
                                                                </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span class="title"> Term & Conditions </span>
                                                    </div>
                                                </li>
                                                <li class="extra-info-list-item" v-if="currentSelectedPlan?.why_buy && (currentSelectedPlan?.why_buy !== '' || currentSelectedPlan?.why_buy !== null)">
                                                    <div class="package-info-box" @click="packageExtraInfo('WhyBuy')">
                                                        <span class="icon-holder">
                                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.88938 5.80801C2.28764 5.70845 2.69122 5.95059 2.79078 6.34885L3.16934 7.8631C3.77372 10.2806 5.67827 12.155 8.09994 12.7234H15.9448C18.0172 12.7234 19.7616 14.2746 20.0037 16.3328L20.6474 21.8041C20.6954 22.2118 20.4037 22.5812 19.996 22.6292C19.5883 22.6771 19.2189 22.3855 19.171 21.9778L18.5273 16.5065C18.3732 15.1969 17.2634 14.21 15.9448 14.21H7.93457L7.85492 14.1923C4.84677 13.5239 2.47448 11.2132 1.7271 8.22366L1.34854 6.70941C1.24898 6.31115 1.49112 5.90758 1.88938 5.80801Z" fill="#1C274C"/>
                                                                <path d="M8.01562 14.21V18.4221C8.01562 20.2909 8.01562 21.2253 8.59619 21.8058C9.17675 22.3864 10.1112 22.3864 11.98 22.3864C13.8488 22.3864 14.7832 22.3864 15.3637 21.8058C15.9443 21.2253 15.9443 20.2909 15.9443 18.4221V14.21H8.01562Z" fill="#2F73FE"/>
                                                                <path d="M8.01562 14.21V18.4221C8.01562 20.2909 8.01562 21.2253 8.59619 21.8058C9.17675 22.3864 10.1112 22.3864 11.98 22.3864C13.8488 22.3864 14.7832 22.3864 15.3637 21.8058C15.9443 21.2253 15.9443 20.2909 15.9443 18.4221V14.21H8.01562Z" fill="url(#paint0_linear_3110_7644)"/>
                                                                <circle cx="11.98" cy="6.52928" r="3.96434" fill="#1C274C"/>
                                                                <defs>
                                                                <linearGradient id="paint0_linear_3110_7644" x1="10.004" y1="18.2982" x2="15.7073" y2="18.3459" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#2F73FE"/>
                                                                <stop offset="1" stop-color="#4FADF8"/>
                                                                </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span class="title"> Why Buy </span>
                                                    </div>
                                                </li>
                                                <li class="extra-info-list-item" v-if="currentSelectedPlan?.covers && (currentSelectedPlan?.covers !== '' || currentSelectedPlan?.covers !== null)">
                                                    <div class="package-info-box" @click="packageExtraInfo('Coverages')">
                                                        <span class="icon-holder">
                                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.98352 5.42993C3.60938 5.963 3.60938 7.54754 3.60938 10.7166V12.2773C3.60938 17.8651 7.81054 20.5767 10.4464 21.7282C11.1615 22.0405 11.519 22.1967 12.5291 22.1967C13.5393 22.1967 13.8968 22.0405 14.6118 21.7282C17.2477 20.5768 21.4489 17.8651 21.4489 12.2773V10.7166C21.4489 7.54754 21.4489 5.963 21.0747 5.42993C20.7006 4.89685 19.2107 4.38686 16.2309 3.36687L15.6632 3.17254C14.1099 2.64085 13.3333 2.375 12.5291 2.375C11.725 2.375 10.9483 2.64085 9.39505 3.17254L8.82735 3.36687C5.84757 4.38686 4.35767 4.89685 3.98352 5.42993Z" fill="#2F73FE"/>
                                                                <path d="M3.98352 5.42993C3.60938 5.963 3.60938 7.54754 3.60938 10.7166V12.2773C3.60938 17.8651 7.81054 20.5767 10.4464 21.7282C11.1615 22.0405 11.519 22.1967 12.5291 22.1967C13.5393 22.1967 13.8968 22.0405 14.6118 21.7282C17.2477 20.5768 21.4489 17.8651 21.4489 12.2773V10.7166C21.4489 7.54754 21.4489 5.963 21.0747 5.42993C20.7006 4.89685 19.2107 4.38686 16.2309 3.36687L15.6632 3.17254C14.1099 2.64085 13.3333 2.375 12.5291 2.375C11.725 2.375 10.9483 2.64085 9.39505 3.17254L8.82735 3.36687C5.84757 4.38686 4.35767 4.89685 3.98352 5.42993Z" fill="url(#paint0_linear_3110_7651)"/>
                                                                <path d="M15.5618 10.7984C15.8352 10.4922 15.8086 10.0223 15.5024 9.74891C15.1962 9.4755 14.7263 9.5021 14.4529 9.80832L11.4678 13.1517L10.6064 12.1869C10.333 11.8807 9.86308 11.8541 9.55686 12.1275C9.25063 12.4009 9.22404 12.8708 9.49745 13.177L10.9133 14.7628C11.0543 14.9207 11.256 15.011 11.4678 15.011C11.6795 15.011 11.8812 14.9207 12.0222 14.7628L15.5618 10.7984Z" fill="#1C274C"/>
                                                                <defs>
                                                                <linearGradient id="paint0_linear_3110_7651" x1="8.08324" y1="12.2858" x2="20.9158" y2="12.3854" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#2F73FE"/>
                                                                <stop offset="1" stop-color="#4FADF8"/>
                                                                </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span class="title"> Coverages </span>
                                                    </div>
                                                </li>
                                                <li class="extra-info-list-item" v-if="currentSelectedPlan?.exclusions && (currentSelectedPlan?.exclusions !== '' || currentSelectedPlan?.exclusions !== null)">
                                                    <div class="package-info-box" @click="packageExtraInfo('Exclusions')">
                                                        <span class="icon-holder">
                                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.26904 17.3078L5.68076 18.7195L7.09275 20.1313C7.95211 20.9907 8.38179 21.4204 8.92819 21.6467C9.47458 21.873 10.0822 21.873 11.2976 21.873H13.5792C14.7945 21.873 15.4022 21.873 15.9486 21.6467C16.495 21.4204 16.9247 20.9907 17.784 20.1313L20.6075 17.3078C21.4669 16.4485 21.8966 16.0188 22.1229 15.4724C22.3492 14.926 22.3492 14.3183 22.3492 13.103V10.8214C22.3492 9.60606 22.3492 8.9984 22.1229 8.452C21.8966 7.9056 21.4669 7.47592 20.6075 6.61656L19.1957 5.2048L17.7838 3.79296C16.9245 2.9336 16.4948 2.50392 15.9484 2.27759C15.402 2.05127 14.7943 2.05127 13.579 2.05127H11.2974C10.0821 2.05127 9.47439 2.05127 8.92799 2.2776C8.3816 2.50392 7.95191 2.9336 7.09255 3.79296L4.26904 6.61648C3.40967 7.47584 2.97999 7.90552 2.75367 8.45192C2.52734 8.99832 2.52734 9.60598 2.52734 10.8213V13.1029C2.52734 14.3183 2.52734 14.9259 2.75367 15.4723C2.97999 16.0187 3.40968 16.4484 4.26904 17.3078Z" fill="#2F73FE"/>
                                                                <path d="M4.26904 17.3078L5.68076 18.7195L7.09275 20.1313C7.95211 20.9907 8.38179 21.4204 8.92819 21.6467C9.47458 21.873 10.0822 21.873 11.2976 21.873H13.5792C14.7945 21.873 15.4022 21.873 15.9486 21.6467C16.495 21.4204 16.9247 20.9907 17.784 20.1313L20.6075 17.3078C21.4669 16.4485 21.8966 16.0188 22.1229 15.4724C22.3492 14.926 22.3492 14.3183 22.3492 13.103V10.8214C22.3492 9.60606 22.3492 8.9984 22.1229 8.452C21.8966 7.9056 21.4669 7.47592 20.6075 6.61656L19.1957 5.2048L17.7838 3.79296C16.9245 2.9336 16.4948 2.50392 15.9484 2.27759C15.402 2.05127 14.7943 2.05127 13.579 2.05127H11.2974C10.0821 2.05127 9.47439 2.05127 8.92799 2.2776C8.3816 2.50392 7.95191 2.9336 7.09255 3.79296L4.26904 6.61648C3.40967 7.47584 2.97999 7.90552 2.75367 8.45192C2.52734 8.99832 2.52734 9.60598 2.52734 10.8213V13.1029C2.52734 14.3183 2.52734 14.9259 2.75367 15.4723C2.97999 16.0187 3.40968 16.4484 4.26904 17.3078Z" fill="url(#paint0_linear_3110_7640)"/>
                                                                <path d="M19.1985 5.20508L5.68359 18.7198" stroke="#1C274C" stroke-width="1.1893"/>
                                                                <defs>
                                                                <linearGradient id="paint0_linear_3110_7640" x1="7.49835" y1="11.9622" x2="21.7567" y2="12.0851" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#2F73FE"/>
                                                                <stop offset="1" stop-color="#4FADF8"/>
                                                                </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span class="title"> Exlusions </span>
                                                    </div>
                                                </li>
                                                <li class="extra-info-list-item" v-if="currentSelectedPlan?.brochure && (currentSelectedPlan?.brochure !== '' || currentSelectedPlan?.brochure !== null)">
                                                    <a class="package-info-box" :href="currentSelectedPlan?.brochure">
                                                        <span class="icon-holder">
                                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.99561 13.1603C9.99561 11.9288 10.994 10.9304 12.2256 10.9304C13.4571 10.9304 14.4555 11.9288 14.4555 13.1603C14.4555 14.3919 13.4571 15.3903 12.2256 15.3903C10.994 15.3903 9.99561 14.3919 9.99561 13.1603Z" fill="#1C274C"/>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29688 18.8039V4.92871C4.29688 5.47607 4.7406 5.9198 5.28796 5.9198H17.181C18.8231 5.9198 20.1542 7.25097 20.1542 8.89305V18.8039C20.1542 20.446 18.8231 21.7771 17.181 21.7771H7.27013C5.62805 21.7771 4.29688 20.446 4.29688 18.8039ZM8.50898 13.1603C8.50898 11.1077 10.1729 9.44376 12.2256 9.44376C14.2782 9.44376 15.9421 11.1077 15.9421 13.1603C15.9421 15.2129 14.2782 16.8769 12.2256 16.8769C10.1729 16.8769 8.50898 15.2129 8.50898 13.1603ZM9.50007 19.1068C9.50007 18.6963 9.83286 18.3635 10.2434 18.3635H14.2077C14.6182 18.3635 14.951 18.6963 14.951 19.1068C14.951 19.5174 14.6182 19.8501 14.2077 19.8501H10.2434C9.83286 19.8501 9.50007 19.5174 9.50007 19.1068Z" fill="#1C274C"/>
                                                                <path d="M18.1721 4.24084V6.08888C17.8621 5.97932 17.5285 5.9197 17.181 5.9197H5.28796C4.7406 5.9197 4.29688 5.47598 4.29688 4.92862V4.8582C4.29688 4.32994 4.68532 3.88206 5.20827 3.80735L15.9096 2.27859C17.1037 2.10801 18.1721 3.03459 18.1721 4.24084Z" fill="#2F73FE"/>
                                                                <path d="M18.1721 4.24084V6.08888C17.8621 5.97932 17.5285 5.9197 17.181 5.9197H5.28796C4.7406 5.9197 4.29688 5.47598 4.29688 4.92862V4.8582C4.29688 4.32994 4.68532 3.88206 5.20827 3.80735L15.9096 2.27859C17.1037 2.10801 18.1721 3.03459 18.1721 4.24084Z" fill="url(#paint0_linear_3110_7668)"/>
                                                                <defs>
                                                                <linearGradient id="paint0_linear_3110_7668" x1="7.77655" y1="4.17359" x2="17.7483" y2="4.48509" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#2F73FE"/>
                                                                <stop offset="1" stop-color="#4FADF8"/>
                                                                </linearGradient>
                                                                </defs>
                                                            </svg>

                                                        </span>
                                                        <span class="title"> Brochure </span>
                                                    </a>
                                                </li>
                                                <li class="extra-info-list-item" v-if="currentSelectedPlan?.claim_example && (currentSelectedPlan?.claim_example !== '' || currentSelectedPlan?.claim_example !== null)">
                                                    <a class="package-info-box" :href="currentSelectedPlan?.claim_example">
                                                        <span class="icon-holder">
                                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86914 5.26123V19.1364C4.86914 20.7785 6.20031 22.1097 7.84239 22.1097H17.7532C19.3953 22.1097 20.7265 20.7785 20.7265 19.1364V9.22557C20.7265 7.58349 19.3953 6.25232 17.7532 6.25232H5.86023C5.31286 6.25232 4.86914 5.80859 4.86914 5.26123ZM8.09017 12.1988C8.09017 11.7883 8.42296 11.4555 8.83348 11.4555H16.7622C17.1727 11.4555 17.5055 11.7883 17.5055 12.1988C17.5055 12.6093 17.1727 12.9421 16.7622 12.9421H8.83348C8.42296 12.9421 8.09017 12.6093 8.09017 12.1988ZM8.09017 15.6676C8.09017 15.2571 8.42296 14.9243 8.83348 14.9243H14.2844C14.695 14.9243 15.0278 15.2571 15.0278 15.6676C15.0278 16.0781 14.695 16.4109 14.2844 16.4109H8.83348C8.42296 16.4109 8.09017 16.0781 8.09017 15.6676Z" fill="#1C274C"/>
                                                                <path d="M18.7443 4.57336V6.4214C18.4343 6.31184 18.1007 6.25222 17.7532 6.25222H5.86023C5.31286 6.25222 4.86914 5.8085 4.86914 5.26114V5.19072C4.86914 4.66246 5.25758 4.21458 5.78054 4.13987L16.4818 2.61111C17.676 2.44053 18.7443 3.36711 18.7443 4.57336Z" fill="#2F73FE"/>
                                                                <path d="M18.7443 4.57336V6.4214C18.4343 6.31184 18.1007 6.25222 17.7532 6.25222H5.86023C5.31286 6.25222 4.86914 5.8085 4.86914 5.26114V5.19072C4.86914 4.66246 5.25758 4.21458 5.78054 4.13987L16.4818 2.61111C17.676 2.44053 18.7443 3.36711 18.7443 4.57336Z" fill="url(#paint0_linear_3110_7663)"/>
                                                                <defs>
                                                                <linearGradient id="paint0_linear_3110_7663" x1="8.34881" y1="4.50611" x2="18.3206" y2="4.81761" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#2F73FE"/>
                                                                <stop offset="1" stop-color="#4FADF8"/>
                                                                </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span class="title"> Policy Wording </span>
                                                    </a>
                                                </li>
                                                <li class="extra-info-list-item" v-if="currentSelectedPlan?.glossary && (currentSelectedPlan?.glossary !== '' || currentSelectedPlan?.glossary !== null)">
                                                    <a class="package-info-box" :href="currentSelectedPlan?.glossary">
                                                        <span class="icon-holder">
                                                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.220703 12.9547V2.29093C0.220703 1.24026 1.06397 0.386727 2.10684 0.45118C3.0358 0.508592 4.13445 0.622077 4.97791 0.845893C5.97617 1.11079 7.16252 1.67498 8.10078 2.17388C8.60921 2.44423 9.17373 2.57044 9.73512 2.55558V17.098C9.20174 17.098 8.66837 16.9688 8.18657 16.7106C7.23562 16.2008 6.00685 15.6098 4.97791 15.3368C4.14377 15.1155 3.06007 15.002 2.13772 14.944C1.08275 14.8777 0.220703 14.0176 0.220703 12.9547Z" fill="#2F73FE"/>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.220703 12.9547V2.29093C0.220703 1.24026 1.06397 0.386727 2.10684 0.45118C3.0358 0.508592 4.13445 0.622077 4.97791 0.845893C5.97617 1.11079 7.16252 1.67498 8.10078 2.17388C8.60921 2.44423 9.17373 2.57044 9.73512 2.55558V17.098C9.20174 17.098 8.66837 16.9688 8.18657 16.7106C7.23562 16.2008 6.00685 15.6098 4.97791 15.3368C4.14377 15.1155 3.06007 15.002 2.13772 14.944C1.08275 14.8777 0.220703 14.0176 0.220703 12.9547Z" fill="url(#paint0_linear_3171_7650)"/>
                                                                <path d="M19.2507 12.9677V2.27571C19.2507 1.25286 18.4456 0.413239 17.4259 0.448846C16.3521 0.486341 15.0139 0.5979 14.0178 0.896822C13.1543 1.15598 12.1485 1.67579 11.3289 2.1513C10.8388 2.43564 10.2891 2.58656 9.73633 2.60115V17.098C10.2697 17.098 10.8031 16.9692 11.2849 16.7118C12.2358 16.2037 13.4646 15.6145 14.4935 15.3423C15.3277 15.1217 16.4114 15.0086 17.3337 14.9508C18.3887 14.8846 19.2507 14.0273 19.2507 12.9677Z" fill="#1C274C"/>
                                                                <defs>
                                                                <linearGradient id="paint0_linear_3171_7650" x1="2.60676" y1="8.77286" x2="9.45105" y2="8.80659" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#2F73FE"/>
                                                                <stop offset="1" stop-color="#4FADF8"/>
                                                                </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span class="title"> Glossary </span>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="row">
                                                <div class="col align-right">
                                                    <button  @click.prevent="generateOrder()" class="btn btn-primary">Next <img src="../assets/images/icons/arrow-up-right.png" alt=""> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('upload-info') }" id="upload-info">
                                        <div class="package-user-detail-section">
                                            <div class="package-box">
                                                <div class="package-detail-box clearfix">
                                                    <span class="package-logo"> <img :src="currentSelectedPlan.company_logo" :alt="currentSelectedPlan.company_name" /> </span>
                                                    <div class="package-detail-text">
                                                        <strong class="title"> {{currentSelectedPlan.company_name}} </strong>
                                                        <span v-if="currentSelectedPlan?.plan_name" class="title-dot"> {{ currentSelectedPlan?.plan_name }} </span>
                                                        <span v-else class="title-dot"> {{ currentSelectedPlan?.product_name }} </span>   
                                                    </div>
                                                    <div class="sum-total-package">
                                                        <span class="total-head"> Total </span>
                                                        <span class="total-head-grand"> {{ currentSelectedPlan?.sum_insured_currency }}  {{ currentSelectedPlan && currentSelectedPlan.sum_insured_value ? currentSelectedPlan.sum_insured_value.toLocaleString('en-US') : '' }} </span>
                                                    </div>
                                                </div>
                                                <div class="package-box-foot clearfix">
                                                    <ul class="package-detail-stat">
                                                        <li>
                                                            <span class="stat-title"> Premium Amount </span>
                                                            <span class="stat-number">  {{ currentSelectedPlan?.sum_insured_currency }}  {{ currentSelectedPlan && currentSelectedPlan.price ? currentSelectedPlan.price.toLocaleString('en-US') : '' }} </span>
                                                        </li>
                                                        <li style="display:none">
                                                            <span class="stat-title"> Tracker </span>
                                                            <span class="stat-number">  139,650 </span>
                                                        </li>
                                                        <li>
                                                            <span class="stat-title"> Cover Limit </span>
                                                            <span class="stat-number" v-if="currentSelectedPlan.covers"> {{ currentSelectedPlan.covers[0].currency_code }} {{ currentSelectedPlan && currentSelectedPlan.sum_insured_value ? currentSelectedPlan.sum_insured_value.toLocaleString('en-US') : '' }}   </span>
                                                            <span class="stat-number" v-else>  0   </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="package-user-detail-tabs">
                                                <div class="nav-tab-wrapper mb-5">
                                                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                                        <li class="nav-item" role="presentation" v-for="(tab, key) in riskSectionTabs" :key="key" :class="tab">
                                                            <button class="nav-link" :button-id="key" @click.prevent="setActiveSub(key)" :class="[key, tab, { active: isActiveSub(key)}]">
                                                                <span v-if="tab == 'Insured_Detail'"> Insured Detail</span>
                                                                <span v-if="tab == 'Contact_Details'"> Contact Details</span>
                                                                <span v-if="tab == 'Vehicle_Details'"> Vehicle Details</span>
                                                                <span v-if="tab == 'Personal_Details'"> Personel Details</span>
                                                                <span v-if="tab == 'Declaration'"> Declaration</span>
                                                                <span v-if="tab == 'Documents'"> Documents</span>
                                                            </button>
                                                        </li>
                                                        <!-- <li class="nav-item" role="presentation">
                                                            static
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <button class="nav-link" disabled @click.prevent="setActiveSub('contact-detail')" :class="{ active: isActiveSub('contact-detail')}">Contact Details</button>
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <button class="nav-link" disabled @click.prevent="setActiveSub('vehicle-detail')" :class="{ active: isActiveSub('vehicle-detail')}">Vehicle Details</button>
                                                        </li> -->
                                                    </ul>
                                                </div>
                                                <div class="tab-content user-tabs" id="pills-tabContent">
                                                    <div v-for="(tab, key) in riskSectionTabs" :key="key" class="tab-pane fade" :class="{ 'active show': isActiveSub(key) }" :button-id="key" :id="tab">
                                                        <div class="row">
                                                            <div v-for="(value,key) in dynamicTabData[tab]" :key="key" class="col-md-6 mb-4" >
                                                                <div class="form-group">
                                                                    <select :id="value.field_name" @change="setAdditionFormFieldValues($event)" v-if="value.type == 'dropDown' && value.field_name !== 'nationality'"  class="form-control">
                                                                        <option> {{ value.label }} </option>
                                                                        <option :value="options.id" :data-id="options.form_fields_id" v-for="(options , jKey) in value.default_values"> {{options.text}}</option>
                                                                    </select>

                                                                    <select :id="value.field_name" @change="setAdditionFormFieldValues($event)" v-else-if="value.field_name == 'nationality'"  class="form-control">
                                                                        <option> {{ value.label }} </option>
                                                                        <option data-id="408" value="6072">Pakistan</option>
                                                                        <template v-for="(options , jKey) in value.default_values">
                                                                            <option :value="options.id" :data-id="options.form_fields_id" v-if="options.text !== 'Pakistan'">
                                                                                {{options.text}}
                                                                            </option>
                                                                        </template>
                                                                    </select>

                                                                    <input :id="value.field_name" @change="setAdditionFormFieldValues($event)" v-else-if="value.type == 'date' && (value.field_name !== 'date_of_birth' && value.field_name !== 'survey_date')" :value="value.label" :type="value.inputType" onfocus="(this.type='date')" onblur="(this.type='text')" :placeholder="value.label" class="form-control" />
                                                                    <input :id="value.field_name" @change="setAdditionFormFieldValues($event)" v-else-if="value.field_name == 'survey_date'" :value="value.label" :type="value.inputType" onfocus="(this.type='date')" onblur="(this.type='text')" :placeholder="value.label" class="form-control" :min="getMinDate()" :max="getMaxDate()" />
                                                                    <input :id="value.field_name" @change="setAdditionFormFieldValues($event)" v-else-if="value.field_name == 'date_of_birth'" :max="maximumDate" :type="value.inputType" :value="value.label" onfocus="(this.type='date')" onblur="(this.type='text')" :placeholder="value.label" class="form-control" />
                                                                    <input :id="value.field_name" @change="setAdditionFormFieldValues($event)" @keypress="checkNumber" v-else-if="value.field_name == 'cnic_number'" type="text" maxlength="13" :placeholder="value.label" class="form-control" />
                                                                    <input :id="value.field_name" @change="setAdditionFormFieldValues($event)" @keypress="checkNumber" v-else-if="value.field_name == 'mobile_no'" type="text" maxlength="11" :placeholder="value.label" class="form-control" />
                                                                    <!-- <input :id="value.field_name" @change="setAdditionFormFieldValues($event)" v-else-if="value.field_name == 'cnic_expiry' || value.field_name == 'cnic_expirydate'" :value="value.label" :type="value.inputType" onfocus="(this.type='date')" onblur="(this.type='text')" :placeholder="value.label" class="form-control" /> -->
                                                                    <input :id="value.field_name" @keyup="setAdditionFormFieldValues($event)" v-else :type="value.inputType" :placeholder="value.label" class="form-control" />
                                                                    <label :for="value.field_name"  v-if="value.type == 'text'" class="absolute-label"> {{ value.field_name }} *</label>
                                                                    <span class="text-danger" v-if="finalValidationsFields[value.field_name]">{{finalValidationsFields[value.field_name][0]}}</span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div v-if="tab=='Documents'">
                                                            

                                                        <div class="row">
                                                            <div v-for="(value,key) in riskDocuments.documents" :key="key" class="col-12" >
                                                                <!-- <h6>{{ key }}</h6> -->
                                                                <div class="row upload-documents-inner mb-4">
                                                                    <h2> {{ value.label }} <em> * </em> </h2>
                                                                    <div class="col-6 uploaded-files" v-if="uploadedFiles[value.field_name]" v-for="(file, index) in uploadedFiles[value.field_name]" :key="index" :id="`file-${value.field_name+'-'+index}`">
                                                                        <div class="uploaded-files-item">
                                                                            <span class="file-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/></svg>
                                                                            </span>
                                                                            {{ file.name }}
                                                                            <span class="remove-icon" @click="removeFile(index, value.field_name)">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3">
                                                                        <label class="upload-thumb" :for="value.field_name"> 
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>
                                                                            Upload Image 
                                                                            <input :id="value.field_name" @change="getDocument($event, value.field_name)" multiple type="file" />
                                                                        </label>
                                                                        <span class="text-danger" v-if="finalValidationsFields[value.field_name]">{{finalValidationsFields[value.field_name][0]}}</span>
                                                                    </div>
                                                                    
                                                                    <!-- <label :for="valueInner.field_name"> {{ valueInner.field_name }} *</label>
                                                                    <input :id="valueInner.field_name"  @change="(e) => getDocument(e, keyInner, valueInner.field_name)" multiple type="file" />
                                                                    <span class="text-danger" v-if="finalValidationsFields[valueInner.field_name]">{{finalValidationsFields[valueInner.field_name][0]}}</span> -->
                                                                    
                                                                </div>
                                                            </div>

                                                            <div v-for="(value,key) in riskDocuments.inspection_images" :key="key" class="col-12" >
                                                                <!-- <h6>{{ key }}</h6> -->
                                                                <div class="row upload-documents-inner mb-4">
                                                                    <h2> {{ value.label }} <em> * </em> </h2>
                                                                    <div class="uploaded-files mb-3" v-if="uploadedFiles[value.field_name]">
                                                                        <div class="uploaded-files-images" v-for="(file, index) in uploadedFiles[value.field_name]" :key="index" :id="`file-${value.field_name+'-'+index}`">
                                                                            <img :src="file.url" />
                                                                            <!-- <span class="remove-icon" @click="removeFile(index, value.field_name)">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
                                                                            </span> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col">
                                                                        <label class="upload-thumb" :for="value.field_name"> 
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>
                                                                            Upload Image 
                                                                            <input :id="value.field_name" @change="getDocument($event, value.field_name)" multiple type="file" />
                                                                        </label>
                                                                        <span class="text-danger" v-if="finalValidationsFields[value.field_name]">{{finalValidationsFields[value.field_name][0]}}</span>
                                                                    </div>
                                                                    
                                                                    <!-- <label :for="valueInner.field_name"> {{ valueInner.field_name }} *</label>
                                                                    <input :id="valueInner.field_name"  @change="(e) => getDocument(e, keyInner, valueInner.field_name)" multiple type="file" />
                                                                    <span class="text-danger" v-if="finalValidationsFields[valueInner.field_name]">{{finalValidationsFields[valueInner.field_name][0]}}</span> -->
                                                                    
                                                                </div>
                                                                
                                                            </div>

                                                            
                                                        </div>
                                                        <!-- <div class="row">
                                                            <div class="col align-right">
                                                                <button type="Submit" @click.prevent="setActiveSub('upload-images')" class="btn btn-primary me-2">Back </button>
                                                                <button type="Submit" @click.prevent="storeAdditionInfomation()" class="btn btn-primary">Next <img src="../assets/images/icons/arrow-up-right.png" alt=""> </button>
                                                            </div>
                                                        </div> -->
                                                    



                                                        </div>

                                                        <div class="row">
                                                            <div class="col align-right">
                                                                <button type="Submit" v-if="tab === getLastTab" @click.prevent="storeAdditionInfomation()" class="btn btn-primary"> Next <img src="../assets/images/icons/arrow-up-right.png" alt=""> </button>
                                                                <button type="Submit" v-else :button-id="key" @click.prevent="setActiveSub(key+1)" class="btn btn-primary">Next <img src="../assets/images/icons/arrow-up-right.png" alt=""> </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    

                                                    

                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('payment') }" id="payment">
                                        <div class="payment-methods">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h1 class="mb-4"> Choose Payment Method </h1>
                                                </div>
                                                <div class="col">
                                                    <ul class="payment-methods-list">
                                                        <li class="payment-methods-list-item radio-inner" v-for="(value,key) in paymentMethods" :key="key">
                                                            <label class="check-holder" :for="value.id">
                                                                <span class="logo-holder">
                                                                    <img :src="value.logo" />
                                                                </span>
                                                                <span class="title"> {{ value.name }} </span>
                                                                <input :id="value.id"  
                                                                v-model="slectedPaymentMethod" type="radio" name="paymentMethod" :value="value.payment_method" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <form id='PayFast_payment_form' hidden name='PayFast-payment-form' method='post' action="https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction">
                                                    <input type="text" hidden name="CURRENCY_CODE" value="PKR"/><br/>
                                                    <input type="text" hidden name="MERCHANT_ID" :value="paymentMerchantID"/><br/>
                                                    <input type="text" hidden name="MERCHANT_NAME" :value="paymentMerchantName"/><br/>
                                                    <input type="text" hidden name="TOKEN" :value="paymentToken"/><br/>
                                                    <input type="text" hidden name="SUCCESS_URL" :value="paymentUrl+currentSelectedPlan.plan_id+'/'+subClassId+'/'+ozoneToken"/><br/>
                                                    <input type="text" hidden name="FAILURE_URL" :value="paymentUrl+currentSelectedPlan.plan_id+'/'+subClassId+'/'+ozoneToken"/><br/>
                                                    <input type="text" hidden name="CHECKOUT_URL" :value="paymentUrl+currentSelectedPlan.plan_id+'/'+subClassId+'/'+ozoneToken"/><br/>
                                                    <input type="text" hidden name="CUSTOMER_EMAIL_ADDRESS" :value="AdditionalformSelectedFieldsValues.email"/><br/>
                                                    <input type="text" hidden name="CUSTOMER_MOBILE_NO" :value="AdditionalformSelectedFieldsValues.mobile_no"/><br/>
                                                    <input type="text" hidden name="TXNAMT" :value="currentSelectedPlan.price"/><br/>
                                                    <input type="text" hidden name="BASKET_ID" :value="customerQoutation.customer_quotation_id"/><br/>
                                                    <input type="text" hidden name="ORDER_DATE" :value="paymentDate"/><br/>
                                                    <input type="text" hidden name="SIGNATURE" :value="signature"/><br/>
                                                    <input type="text" hidden name="VERSION" :value="version"/><br/>
                                                    <input  type="text" hidden name="TXNDESC" :value="txnDesc"/><br/>
                                                    <input type="text" hidden name="PROCCODE" :value="procCode"/><br/>
                                                    <input  type="text" hidden name="TRAN_TYPE" :value='tranType'/><br/>
                                                    <input type="hidden" NAME="MERCHANT_USERAGENT" value="Mozilla/5.0
                                                (Windows NT 6.1; Win64; x64; rv:47.0) Gecko/20100101 Firefox/47.0">
                                                    <input type="hidden" NAME="ITEMS[0][SKU]" value="INSURANCE-SKU-01">
                                                    <input type="hidden" NAME="ITEMS[0][NAME]" value="Insurance">
                                                    <input type="hidden" NAME="ITEMS[0][PRICE]" value="500">
                                                    <input type="hidden" NAME="ITEMS[0][QTY]" value="1">
                                                    <button ref="payfastForm" type="submit"></button>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="row mt-5">
                                            <div class="col align-right">
                                                <button type="Submit" @click.prevent="storePaymentMethod()" class="btn btn-primary">Next <img src="../assets/images/icons/arrow-up-right.png" alt=""> </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('summary') }" id="summary">
                                        <!-- {{ customerSummary }} -->
                                        <div class="summary-main">
                                            <h1> Customer Summary Details </h1>
                                            <div class="head-section">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <p> Order No : <span> {{customerSummary.tcn_no}} </span> </p>
                                                        <p> Order Date : <span> {{customerSummary.coverage_initiate_at}} </span> </p>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <i> <span>DISCLAIMER: </span>
                                                        "This document only serves the purpose of acknowledging the order placed by you on our website/app and by no means is a confirmation of your policy approval and/or issuance. Your policy Coverage will only start once the Insurer approves and issues the policy in accordance with the terms and conditions of the Insurer. The issued policy will then be made available to you digitally within the app. You acknowledge and agree that you have read, understand, and agree to be bound by, and comply with the terms and conditions and privacy policy of Inxurehub as found on our website/app".
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="summary-detail-box">
                                                <h2> Product Detail </h2>
                                                <div class="row">
                                                    <div class="col-md-3 mb-3">
                                                        <label>Major Class</label>
                                                        <p>{{customerSummary.insurance_type}}</p>
                                                    </div>
                                                    <div class="col-md-3 mb-3">
                                                        <label>Sub Class</label>
                                                        <p>{{customerSummary.insurance_sub_type}}</p>
                                                    </div>
                                                    <div class="col-md-3 mb-3">
                                                        <label>Company</label>
                                                        <p>{{customerSummary?.company?.company_name}}</p>
                                                    </div>
                                                    <div class="col-md-3 mb-3">
                                                        <label>Product / Plan</label>
                                                        <p>{{customerSummary?.product?.product_name}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="summary-detail-box">
                                                <h2> Quote Information </h2>
                                                <div class="row">
                                                    <template v-if="customerSummary?.risks">
                                                        <template v-for="(value, key) in customerSummary.risks[0]?.form_data">
                                                            <template v-if="key === 'stage_1'">
                                                                <div v-for="(stageValue, stageKey) in value" :key="stageKey" class="col-md-3 mb-3">
                                                                    <label>{{stageValue?.label}}</label>
                                                                    <p>{{stageValue?.value}}</p>
                                                                </div>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </div>
                                            </div>

                                            <div class="summary-detail-box">
                                                <h2> Insured Detail </h2>
                                                <div class="row">
                                                    <template v-if="customerSummary?.risks">
                                                        <template v-for="(value, key) in customerSummary.risks[0]?.form_data">
                                                            <template v-if="key === 'stage_3'">
                                                                <div v-for="(stageValue, stageKey) in value" :key="stageKey" class="col-md-3 mb-3">
                                                                    <label>{{stageValue?.label}}</label>
                                                                    <p>{{stageValue?.value}}</p>
                                                                </div>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </div>
                                            </div>

                                            <div class="summary-detail-box">
                                                <h2> Sum Insured Detail </h2>
                                                <div class="row">
                                                    <div class="col-md-3 mb-3">
                                                        <label>Sum Insured</label>
                                                        <p>{{customerSummary?.sum_insured_currency}} {{customerSummary?.sum_insured}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="summary-detail-box mb-5">
                                                <h2> Premium Detail </h2>
                                                <div class="row">
                                                    <div class="col-md-3 mb-3">
                                                        <label>Net Premium</label>
                                                        <p>{{customerSummary?.sum_insured_currency}} {{customerSummary?.paid_amount}}</p>
                                                    </div>
                                                    <div class="col-md-3 mb-3">
                                                        <label>Payment Status</label>
                                                        <p>{{customerSummary?.payment_status}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="chk-inner">
                                                <label class="check-holder">
                                                    I HEREBY AGREE AND CONFIRM THAT ALL MY PERSONAL AND POLICY INFORMATION STATED HERE ARE CORRECT.
                                                    <input type="checkbox" v-model="termsAccept">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>

                                            <div class="row mt-2">
                                                <div class="col align-right">
                                                    <button type="Submit" :disabled="!termsAccept" @click.prevent="getPaymentMethods()" class="btn btn-primary"> Next <img src="../assets/images/icons/arrow-up-right.png" alt=""> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        <img v-if="serviceName == 'Motor'" class="w-100" src="../assets/images/form-image.png" />
        <img v-if="serviceName == 'Health'" class="w-100" src="../assets/images/health-footer-image.png" />
        <img v-if="serviceName == 'PA'" class="w-100" src="../assets/images/pa-footer-image.png" />
        <img v-if="serviceName == 'Travel'" class="w-100" src="../assets/images/travel-footer-image.png" />
        <!-- <div>
            <input type="file" multiple @change="handleFileUpload">
            <ul>
            <li v-for="fileName in uploadedFiles" :key="fileName">{{ fileName }}</li>
            </ul>
        </div> -->
    </div>
    <LoginModal ref="LoginModal"></LoginModal>
    <PackageInfoModal ref="PackageInfoModal"></PackageInfoModal>
</template>

<script>
    import axios from 'axios'
    import LoginModal from './patials/LoginModal.vue'
    import PackageInfoModal from './patials/PackageInfoModal.vue'
    import SignupModal from './patials/SignUpModal.vue'
    import VueMultiselect from 'vue-multiselect'
    export default {
      components:{
        LoginModal,
        SignupModal,
        PackageInfoModal,
        VueMultiselect
      },
      data(){
        return{
            selected: null,
            options: ['list', 'of', 'options'],
            myInputModel: '',
            currentSelectedPlan : {},
            paymentToken:'',
            paymentMerchantID:'',
            paymentMerchantName:'',
            paymentDate:'',
            procCode:'',
            signature:'',
            tranType:'',
            txnDesc:'',
            version:'',
            paymentUrl:'https://inxure.application.zijsoft.com/api/payment-final-payfast/',
            paymentLocalUrl:'https://inxurehub.com/feature-form/',
            formSelectedFieldsValues : {},
            dropDownValue:"",
            showDetailsBox: false,
            showUserDetail: false,
            activeItem: 'personelDetail',
            subActiveItem: 0,
            serviceName:"",
            serviceLogo:"",
            subClassName: "",
            subClassId: "",
            subClassForm: [],
            subClassFormValues: [],
            formFieldValues: [],
            validations:{},
            productPlans: [],
            insureDetail : {},
            ContactDetail : {},
            VehicleDetail : {},
            InspectionImages : {},
            AdditionalInfoImage:{},
            uploadedImagesName:{},
            uploadedDocImage: [],
            GetAdditionInfoDoc:[],
            riskSection: {},
            riskSectionTabs:{},
            dynamicTabData: {},
            dynFields:{},
            getLastTab: '',
            isShowing: true,
            finalValidationsFields: {},
            ozoneToken:'',
            subClassInnerCategories: [],
            customerSummary: [],
            termsAccept: false,
            uploadedFiles: {},
            imageSrc: null,
            riskDocuments: null,
            InspectionImagesLocal:[
                { id: 1, field_name: 'image1', label: 'Upload Image'},
                { id: 2, field_name: 'image2', label: 'Upload Image'},
                { id: 3, field_name: 'image3', label: 'Upload Image'},
                { id: 4, field_name: 'image4', label: 'Upload Image'}
            ],
            Documents : {},
            customerQoutation: {},
            AdditionalformSelectedFieldsValues: {},
            paymentMethods: {},
            slectedPaymentMethod:'',
            paymentInfo:{},
            subClassesList : [],
            congratulations: false,
            loader: false,
            showVehicleImages: false,
            
        }
      },
      methods: {
        packageExtraInfo(type){
            console.log(type);
            this.$refs.PackageInfoModal.selectedPackageName = type
            if(type === 'ProductDetail'){
                this.$refs.PackageInfoModal.selectedPackage = this.currentSelectedPlan.product_description;
                console.log(this.$refs.PackageInfoModal.selectedPackage)
            }
            if(type === 'TermsConditions'){
                this.$refs.PackageInfoModal.selectedPackage = this.currentSelectedPlan.things_to_note;
                console.log(this.$refs.PackageInfoModal.selectedPackage)
            }
            if(type === 'WhyBuy'){
                this.$refs.PackageInfoModal.selectedPackage = this.currentSelectedPlan.why_buy;
                console.log(this.$refs.PackageInfoModal.selectedPackage)
            }
            if(type === 'Coverages'){
                this.$refs.PackageInfoModal.selectedPackage = this.currentSelectedPlan.covers;
                console.log(this.$refs.PackageInfoModal.selectedPackage)
            }
            if(type === 'Exclusions'){
                this.$refs.PackageInfoModal.selectedPackage = this.currentSelectedPlan.exclusions;
                console.log(this.$refs.PackageInfoModal.selectedPackage)
            }
            this.$refs.PackageInfoModal.modal.show()
            // this.$refs.PackageInfoModal.selectedPackage = this.currentSelectedPlan;
            
        },
        getMinDate() {
            const today = new Date();
            const minDate = new Date(today.getTime() + 24 * 60 * 60 * 1000);
            return minDate.toISOString().split('T')[0];
        },
        getMaxDate() {
            const today = new Date();
            const maxDate = new Date(today.getTime() + 2 * 24 * 60 * 60 * 1000);
            return maxDate.toISOString().split('T')[0];
        },
        checkNumber(event) {
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
            }
        },
        showDetails(currentPlan){
            this.currentSelectedPlan = currentPlan
            this.showDetailsBox = true
        },
        openSignup(){
            this.$refs.SignupModal.modal.show()
        },
        getPaymentMethods(){
            let _this=this;
            let url='https://inxure.application.zijsoft.com/api/get-payment-methods';
            let customer_quotation_id = _this.customerQoutation.customer_quotation_id;
            let ozoneToken = "Bearer " + localStorage.getItem('ozone_token')
            let axiosConfig = {
                customer_quotation_id,
                amount: _this.currentSelectedPlan.price
            };
            axios
            .post(url, axiosConfig ,{
                headers: {
                    'Authorization': `${ozoneToken}`
                },
            })
            .then((response) => {
                // console.log(response);
                if(response.data.response_code==200){
                    _this.paymentToken = response.data.data.token
                    _this.paymentDate = response.data.data.date
                    _this.paymentMerchantID = response.data.data.merchantId
                    _this.paymentMerchantName = response.data.data.merchantName
                    _this.procCode = response.data.data.procCode
                    _this.signature = response.data.data.signature
                    _this.tranType = response.data.data.tranType
                    _this.txnDesc = response.data.data.txnDesc
                    _this.version = response.data.data.version
                    _this.paymentMethods = response.data.data.payment_methods
                    _this.activeItem = "payment"
                    
                }
            })
            .catch((e) => {
                if(e.response.data.success==false){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong.'
                    })
                }
            });
        },
        getSummary(){
            let _this=this;
            let url='https://inxure.application.zijsoft.com/api/PolicyDetail';
            let id = _this.customerQoutation.customer_quotation_id;
            // let id = '9341';
            let ozoneToken = "Bearer " + localStorage.getItem('ozone_token')
            let axiosConfig = {
                id
            };
            axios
            .post(url, axiosConfig ,{
                headers: {
                    'Authorization': `${ozoneToken}`
                },
            })
            .then((response) => {
                console.log(response);
                _this.customerSummary = response.data.data;
                // if(response.data.response_code==200){
                //     _this.paymentToken = response.data.data.token
                //     _this.paymentDate = response.data.data.date
                //     _this.paymentMerchantID = response.data.data.merchantId
                //     _this.paymentMerchantName = response.data.data.merchantName
                //     _this.procCode = response.data.data.procCode
                //     _this.signature = response.data.data.signature
                //     _this.tranType = response.data.data.tranType
                //     _this.txnDesc = response.data.data.txnDesc
                //     _this.version = response.data.data.version
                //     _this.paymentMethods = response.data.data.payment_methods
                // }
            })
            .catch((e) => {
                console.log(e)
                // if(e.response.data.success==false){
                //     this.$swal.fire({
                //         icon: 'error',
                //         title: 'Oops...',
                //         text: 'Something went wrong.'
                //     })
                // }
            });
        },
        storePaymentMethod(){
            let _this=this;
            let url='https://inxure.application.zijsoft.com/api/store-payment-info';

            this.paymentInfo['sub_class_id'] = _this.subClassId;
            this.paymentInfo['customer_quotation_id'] = _this.customerQoutation.customer_quotation_id;
            this.paymentInfo['plan_id'] = _this.currentSelectedPlan.plan_id;
            this.paymentInfo['paid_amount'] = _this.currentSelectedPlan.price;
            this.paymentInfo['payment_method'] = _this.slectedPaymentMethod.toLowerCase();

            if(_this.slectedPaymentMethod == 'Credit_Card'){
                this.$refs.payfastForm.click()
            }
            const formDatas = this.paymentInfo;
            let ozoneToken = "Bearer " + localStorage.getItem('ozone_token')
            let data = {
                formDatas
            }
            axios
            .post(url, data, {
                headers: {
                    'Authorization': `${ozoneToken}`
                },
            })
            .then((response) => {
                // console.log(response);
                if(response.data.response_code==200){
                    _this.congratulations = true
                    // _this.activeItem = "summary"
                }
            })
            .catch((e) => {
                if(e.response.data.success==false){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong.'
                    })
                }
            });
        },
        getProductPlanList(){

            let _this=this;
            let url='https://inxure.application.zijsoft.com/api/product/list';

            this.formSelectedFieldsValues["sub_class_id"] = _this.subClassId;
            this.loader = true

            console.log(this.formSelectedFieldsValues);

            // const entries = Object.entries(this.formSelectedFieldsValues);

            let formDatas = this.formSelectedFieldsValues;
            let param = "";
            let axiosConfig = {
                formDatas
            };
            axios
            .post(url, axiosConfig)
            .then((response) => {
                //console.log(response)
                if(response.data.response_code==200){
                    _this.productPlans = response.data.data
                    console.log("Plans" + _this.productPlans)
                    _this.activeItem = "package"
                }
            })
            .catch((e) => {
                _this.validations=e.response.data.errors.errors;
                if(e.response.data.success==false){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong.'
                    })
                }
                var obj = {};
                for (var i = 0; i < _this.validations.length; i++) {
                  obj[(_this.validations[i].field)] = Object.values([_this.validations[i].message])
                }
                 _this.finalValidationsFields = obj
                // console.log('obj')
                // console.log(obj)
                
            }).finally(() => {
                this.loader = false
            }) 
            // this.activeItem = 'package'
        },
        generateOrder(){
            let _this=this;
            let url='https://inxure.application.zijsoft.com/api/order/generate';

            this.formSelectedFieldsValues["plan_id"] = _this.currentSelectedPlan.plan_id;
            this.formSelectedFieldsValues["product_price"] = _this.currentSelectedPlan.price;

            const formDatas = this.formSelectedFieldsValues;
            let localToken = localStorage.getItem('local_token')
            let ozoneToken = "Bearer " + localStorage.getItem('ozone_token')
            
            //console.log(ozoneToken);

            let axiosConfig = {
                formDatas,
            };
            axios
            .post(url, axiosConfig, {
                headers: {
                    'Authorization': `${ozoneToken}`
                },
            })
            .then((response) => {
                //console.log(response)
                if(response.data.response_code==200){

                    _this.customerQoutation = response.data.data;

                    this.getUploadInformationForm();
                    //console.log(_this.customerQoutation);
                    _this.activeItem = "upload-info"
                     
                }
            })
            .catch((e) => {
                console.log(e.response);
                // console.log()
                if(e.response.status==401){
                    
                    this.$refs.LoginModal.modal.show()
                }
            });
            // this.activeItem = 'package'
        },
        getVehicleImage(event) {
            this.AdditionalInfoImage = event.target.files
        },
        removeFile(index, field_name) {
            console.log(index)
            console.log(`file-${field_name+'-'+index}`)
            this.GetAdditionInfoDoc[field_name].splice(index, 1);
            this.uploadedFiles[field_name].splice(index, 1);
            const input = document.getElementById(field_name)
            input.value = ''
            input.type = 'text'
            input.type = 'file'
        },
        getDocument(e, field_name) {
            const files = e.target.files
            let newFiles = { ...this.uploadedFiles }
            if (!newFiles.hasOwnProperty(field_name)) {
                newFiles[field_name] = []
            }
            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                newFiles[field_name].push({
                'name': file.name,
                'file': file,
                'url': URL.createObjectURL(file) // create object URL for the file
                })
            }
            this.uploadedFiles = {
                ...this.uploadedFiles,
                ...newFiles,
            }
            this.GetAdditionInfoDoc[e.target.id] = files
            console.log(this.uploadedFiles)
            this.GetAdditionInfoDoc = this.uploadedFiles
            
        },
        setFormFieldValuesObject(event){
            this.formSelectedFieldsValues[event.target.id] = event.target.value;
            sessionStorage.setItem('formSelectedFieldsValues', JSON.stringify(this.formSelectedFieldsValues));
            console.log(this.formSelectedFieldsValues);
        },
        setFormRadio(event){
            this.formSelectedFieldsValues[event.target.name] = event.target.id;
            // sessionStorage.setItem('formSelectedFieldsValues', JSON.stringify(this.formSelectedFieldsValues));
            console.log(this.formSelectedFieldsValues);
        },
        setAdditionFormFieldValues(event){
            this.AdditionalformSelectedFieldsValues[event.target.id] = event.target.value;
            // console.log(this.AdditionalformSelectedFieldsValues, event.target.id);
        },
        // setFormRadio(event){
        //     this.AdditionalformSelectedFieldsValues[event.target.name] = event.target.value;
        //     // console.log(this.AdditionalformSelectedFieldsValues, event.target.id);
        //     console.log(this.AdditionalformSelectedFieldsValues);
        // },
        storeAdditionInfomation(file){
            let _this=this;
            let url='https://inxure.application.zijsoft.com/api/customer/store/quotation-additional-info';

            // console.log(_this.GetAdditionInfoDoc)
            this.AdditionalformSelectedFieldsValues["sub_class_id"] = _this.customerQoutation.subclass_id;
            this.AdditionalformSelectedFieldsValues["customer_quotation_id"] = _this.customerQoutation.customer_quotation_id;
            this.AdditionalformSelectedFieldsValues["quotation_risk_id"] = _this.customerQoutation.quotation_risk_id;
            // this.AdditionalformSelectedFieldsValues["vehicle_images"] = _this.AdditionalInfoImage;
            
            //this.AdditionalformSelectedFieldsValues["cnic"][0] = _this.GetAdditionInfoDoc.cnic;
            // this.AdditionalformSelectedFieldsValues["cnic"] = _this.GetAdditionInfoDoc.cnic;
            console.log("_this.uploadedFiles")
            console.log(_this.uploadedFiles)

            let images = [];
            
            let obj = [];
            for(let key in _this.uploadedFiles){
                obj[key] = _this.uploadedFiles[key]
            }
            this.AdditionalformSelectedFieldsValues["images"] = obj;

            console.log(images);
            
            // this.AdditionalformSelectedFieldsValues["date_of_birth"] = _this.formSelectedFieldsValues.date_of_birth;
            // this.AdditionalformSelectedFieldsValues["registration_book"] = _this.GetAdditionInfoDoc.registration_book;
            // this.AdditionalformSelectedFieldsValues["self_survey_video"] = _this.GetAdditionInfoDoc.self_survey_video;

            const formDatas = this.AdditionalformSelectedFieldsValues;

            const data = new FormData();
            console.log(formDatas)
            // console.log(data)

            let localToken = localStorage.getItem('local_token')
            let ozoneToken = "Bearer " + localStorage.getItem('ozone_token')

            //console.log("formDatas");
            //console.log(formDatas);
            
            for (let key in formDatas) {
                if (formDatas.hasOwnProperty(key)) {
                    if(key == 'images'){
                        for (let images in formDatas[key]){
                            for (let file in formDatas[key][images]){
                                if(formDatas[key][images][file].file instanceof File){
                                    data.append(images+"[0][]",formDatas[key][images][file].file);
                                }
                            }
                        }
                        
                    }else{
                        data.append(key,formDatas[key]);
                    }
                }
            }
            console.log(data)

            // console.log("data");
            // console.log(data);
            // console.log(formDatas)
            let axiosConfig = {
                data,
            };
            axios
            .post(url, data, {
                headers: {
                    'Authorization': `${ozoneToken}`,
                    'Content-Type': 'multipart/form-data'
                },
            })
            .then((response) => {
                // console.log(response)
                if(response.data.response_code==200){
                    _this.getPaymentMethods();
                    // _this.getSummary();
                    _this.activeItem = "payment"
                     
                }
            })
            .catch((e) => {
                _this.validations=e.response.data.errors.errors;
                if(e.response.data.errors.code==403){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Your email address is not verified.'
                    })
                }
                if(e.response.data.success==false){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong.'
                    })
                }
                var obj = {};
                for (var i = 0; i < _this.validations.length; i++) {
                  obj[(_this.validations[i].field)] = Object.values([_this.validations[i].message])
                }
                 _this.finalValidationsFields = obj
                console.log("_this.finalValidationsFields")
                console.log(_this.finalValidationsFields)
                
                
            });


            
        },
        changeChild(childId,event){

            this.formSelectedFieldsValues[event.target.id] = event.target.value;
            // console.log(this.formSelectedFieldsValues, event.target.id);
            console.log(this.formSelectedFieldsValues);
            
            if(childId){
                

                // alert(event.target.value)
                let _this=this;
                this.loader = true
                let url='https://inxure.application.zijsoft.com/api/get_child_form_field_values';
                let axiosConfig = {
                    child_form_field_id : childId,
                    parent_value_id : event.target.value
                };
                axios
                .post(url, axiosConfig)
                .then((response) => {
                    // debugger
                    this.formFieldValues = response.data.data
                    for (let index = 0; index < this.subClassForm.length; index++) {
                        if(this.subClassForm[index].id == childId){
                            var i;
                            for(i = 0; i < this.formFieldValues.length; i++){
                                this.formFieldValues[i].text = this.formFieldValues[i]['text'];
                            }
                            this.subClassForm[index].defaultvaluesparent = this.formFieldValues;
                        }                        
                    }
                    if(response.data.code==200){}
                })
                .catch((e) => {
                    //console.log(e);
                    if(e.response.data.success==false){
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong.'
                        })
                    }
                }).finally(() => {
                    this.loader = false
                }) 
            }
        },
        continueToUserDetail(){
            this.showUserDetail = true
        },
        isActive (menuItem) {
            return this.activeItem === menuItem
            },
        setActive (menuItem) {
            this.activeItem = menuItem
        },

        isActiveSub (menuItem) {
            return this.subActiveItem === menuItem
            },
        setActiveSub (menuItem) {
            this.subActiveItem = menuItem
        },
        // First SUb form API
        getSingleId(value){
            let vm = this
            this.subClassId = value.subclass_id;
            this.subClassName = value.name;
            if(value.sub_classes){
                vm.subClassInnerCategories = value.sub_classes
                
            }
            this.getSubClassForm()
        },
        getSubClassForm() {
            let _this=this;
            let url='https://inxure.application.zijsoft.com/api/sub-class-form';
            this.loader = true
            let axiosConfig = {
                sub_class_id : _this.subClassId
            };
            axios
            .post(url, axiosConfig)
            .then((response) => {
                // console.log(response)
                _this.subClassForm = response.data.data
                // console.log(_this.subClassForm);

                if(response.data.code==200){
                }
            })
            .catch((e) => {
                if(e.response.data.success==false){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong.'
                    })
                }
            })
            .finally(() => {
                this.loader = false
            }) 
        },
        getUploadInformationForm() {
            let _this=this;
            let url='https://inxure.application.zijsoft.com/api/customer/quote-fields';
            const formDatas = this.customerQoutation;
            let ozoneToken = "Bearer " + localStorage.getItem('ozone_token')
            _this.ozoneToken = localStorage.getItem('ozone_token')
            let axiosConfig = {
                formDatas  
            };
            axios
            .post(url, axiosConfig, {
                headers: {
                    'Authorization': `${ozoneToken}`
                },
            })
            .then((response) => {
                
                //  console.log(response)
                if(response.data.response_code==200){
                    _this.riskSection =  response.data.data.risk_sections;
                    _this.riskDocuments =  response.data.data.risk_documents;
                    console.log('_this.riskSection');
                    console.log(_this.riskDocuments);
                    _this.riskSectionTabs = Object.getOwnPropertyNames(_this.riskSection)
                    _this.riskSectionTabs[_this.riskSectionTabs.length] =   "Documents";
                    
                     console.log("riskSectionTabs");
                     console.log(_this.riskSectionTabs);


                    let riskSectionTabsLength = _this.riskSectionTabs.length;

                    for(let i = 0;i < riskSectionTabsLength;i++){
                        let item = _this.riskSectionTabs[i];
                        if((i + 1) == (riskSectionTabsLength)){
                            _this.getLastTab = item
                            console.log("Last iteration with item : " + _this.getLastTab); 
                        }
                    }
                    let dynFields = [];
                    for (let key in _this.riskSection) {
                        if (_this.riskSection.hasOwnProperty(key)) {
                            _this.dynamicTabData[key] = []; 
                                   for (let keyInner in _this.riskSection[key].custom_fields) {
                                       if (_this.riskSection[key].custom_fields.hasOwnProperty(keyInner)) {
                                          (_this.dynamicTabData[key]).push(_this.riskSection[key].custom_fields[keyInner]);
                                       }
                                   }
                            
                        }
                    };



                // _this.InspectionImages =  response.data.data.risk_documents.inspection_images;
                   _this.Documents =  response.data.data.risk_documents;
                   //console.log(_this.Documents);
                  // console.log("_this.Documents");                   
                }
                
            })
            .catch((e) => {
                if(e.response.data.success==false){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong.'
                    })
                }
            });
        },
      },
      created(){
        
        let feature = localStorage.getItem('service')
        let arr = JSON.parse(feature)
        let vm = this
        vm.subClassesList = arr.subclasses;
        
        if(arr){
            vm.serviceName = arr.name
            vm.serviceLogo = arr.subclasses[0]?.logo
            vm.subClassName = arr.subclasses[0]?.name
            vm.subClassId = arr.subclasses[0]?.subclass_id

            // console.log("array" + arr.categories.length)
            if(arr.categories.length > 0){
                vm.subClassesList = arr?.categories;
                vm.serviceLogo = arr?.logo
                vm.subClassName = arr?.categories[0]?.name
                vm.subClassInnerCategories = arr?.categories[0]?.subclasses
                // vm.subClassId = arr?.categories[0]?.subclasses[0]?.class_id
            }
        }
        // if(vm.serviceName == 'Travel'){
        //     console.log('traverl')
        //     vm.subClassesList = JSON.parse(feature).categories;
        //     // console.log(vm.subClassesList)
        //     vm.subClassName = JSON.parse(feature).categories[0].name
        //     vm.subClassId = 2
        // }
        this.getSubClassForm();
        
      },
      mounted(){
        // const activeItem = sessionStorage.getItem('activeItem')
        // if (activeItem) {
        //     this.activeItem = activeItem
        // }
        // let storedData = sessionStorage.getItem('formSelectedFieldsValues');
        // let vm = this;
        // if (storedData) {
        //     this.formSelectedFieldsValues = JSON.parse(storedData);
        // }
        // if(this.activeItem == 'package'){
        //     this.getProductPlanList()
        // }
      },
      computed: {
            maximumDate() {
                const currentDate = new Date();
                const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
                return maxDate.toISOString().split('T')[0];
            }
        },
      watch:{
            validations:function(){},
            // activeItem(newIndex) {
            //     sessionStorage.setItem('activeItem', newIndex)
            // }
        }
        
    }
</script>

<style>

    @keyframes confetti-slow {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  
  100% { transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); }
}

@keyframes confetti-medium {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  
  100% { transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); }
}

@keyframes confetti-fast {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  
  100% { transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); }
}

.con-container {
  
  background: #ffffff;
  border:1px solid white;
    text-align: center;
  top:0px;
}
.form-submitted{
    padding: 150px 0;
    text-align: center;
}
h1{
    margin: 30px 0 10px;
}
.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;

  &--animation-slow {
    animation: confetti-slow 2.25s linear 1 forwards;
  }
  
  &--animation-medium {
    animation: confetti-medium 1.75s linear 1 forwards;
  }
  
  &--animation-fast {
    animation: confetti-fast 1.25s linear 1 forwards;
  }
}





/* Checkmark */
.checkmark-circle {
  width: 150px;
  height: 150px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
}
.checkmark-circle .background {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #00C09D;
  position: absolute;
}
.checkmark-circle .checkmark {
  border-radius: 5px;
}
.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 75px;
  width: 37.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid white;
  border-top: 15px solid white;
  border-radius: 2.5px !important;
  content: '';
  left: 25px;
  top: 75px;
  position: absolute;
}

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}



.submit-btn {
    height: 45px;
    width: 200px;
    font-size: 15px;
    background-color: #00c09d;
    border: 1px solid #00ab8c;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(87,71,81,.2);
    cursor: pointer;
    transition: all 2s ease-out;
    transition: all .2s ease-out;
}

.submit-btn:hover {
    background-color: #2ca893;
    transition: all .2s ease-out;
}
</style>