<template>
    <section class="top-banner">
        <!-- <div class="number-form desktop-form">
            <div class="form-group">
                <input type="text" class="form-control" v-model="mobileNumber" v-on:input="checkNumber" placeholder="Number"/>
                <button class="btn btn-primary" @click="submitNumber" :disabled="isSubmitting"> <span v-if="isSubmitting"> <fa-icon icon="circle-notch" spin /> </span> Get quote </button>
            </div>
            <span class="text-danger" v-if="!isNumber">Please enter a number only.</span>
        </div> -->
        <div class="banner-car">
            <carousel :items-to-show="1" :autoplay="4000" :wrap-around="true">
                <Slide v-for="slide in homeTopBanner" :class="'banner'+slide.id" :key="slide.id" >
                    <div class="carousel__item">
                        <img :src="slide.image" alt="">
                    </div>
                </Slide>
            </carousel>
        </div>
        <div class="mobile-banner">
            <span class="title"># Banao Har Lamha Mehfoz</span>
            <img src="../assets/images/mobile-banner-image2.png" />
            <!-- <div class="text">
                <h1>Making Insurance Simple,  Transparent &  Accessible to everyone </h1>
            </div> -->
            <!-- <div class="number-form">
                <div class="form-group">
                    <input type="text" class="form-control" v-model="mobileNumber" v-on:input="checkNumber" placeholder="Number"/>
                    <button class="btn btn-primary" @click="submitNumber" :disabled="isSubmitting"> <span v-if="isSubmitting"> <fa-icon icon="circle-notch" spin /> </span> Get quote </button>
                </div>
                <span class="text-danger" v-if="!isNumber">Please enter a number only.</span>
            </div> -->
        </div>
    </section>

    <section class="fetching-number-main">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="fetching-number-content-main">
                        <h2> Let us help you ! Enter your information and we ‘ ll have a representative contact you shortly. </h2>
                        <div class="fetching-number-content">
                            <div class="fetching-number-row">
                                <div class="fetching-number-col">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" v-model="fetchName" id="name" placeholder="Your Name" required>
                                        <label for="name">Your Name</label>
                                    </div>
                                </div>
                                <div class="fetching-number-col">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" v-model="mobileNumber" v-on:input="checkNumber" id="mobile#" placeholder="Mobile #" required>
                                        <label for="mobile#">Mobile # </label>
                                        <span class="text-danger" v-if="!isNumber">Please enter a number only.</span>
                                    </div>
                                </div>
                                <div class="fetching-number-col">
                                    <div class="form-floating">
                                        <select class="form-select form-control" v-model="fetchService" id="selectService" aria-label="Floating label Select Services">
                                            <option value="" selected>Select Services</option>
                                            <option value="motor">Motor</option>
                                            <option value="health">Health</option>
                                            <option value="travel">Travel</option>
                                            <option value="personealAccident">Personal Accident</option>
                                        </select>
                                        <label for="selectService">Insurace Categoory</label>
                                    </div>
                                </div>
                                <div class="fetching-number-col fetching-number-btn">
                                    <button class="btn btn-primary" @click="submitNumber" :disabled="isSubmitting"> <span v-if="isSubmitting"> <fa-icon icon="circle-notch" spin /> </span> Get quote </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<section class="home-services">
    <!-- <div class="get-qoute-section">
        <button class="action-btn btn btn-primary"> Get Quote</button>
    </div> -->
    <div class="container">
        <div class="row align-items-end">
            <div class="col">
                <div class="services-btns align-center">
                    <ul class="btn-list">
                        <li class="btn-list-item"><button class="btn btn-primary me-2" :class="{active: !isTakfulClass}" @click="filtertype('No')">Conventional</button></li>
                        <li class="btn-list-item"><button  class="btn btn-primary" :class="{active: isTakfulClass}" @click="filtertype('Yes')">Takaful (Insurance - The Islamic Way)</button></li>
                    </ul>
                </div>
                <ul class="services-list mt-4">
                    <li class="services-list-item" v-for="(value , key) in filteredServices" :key="key">
                        <div class="service-holder">
                            <!-- <span v-if="value.id===2" class="overhead">Save on Tax</span>
                            <span v-if="value.id===3" class="overhead">Covid-19 covered</span> -->
                            <a href="#" @click.prevent="getService(value)" class="service-inner">
                                <span v-if="value.id===1 || value.id===21" class="icon-holder">
                                    <img src="../assets/images/motor-home-icon.svg" alt="" />
                                </span>
                                <span v-if="value.id===2" class="icon-holder">
                                    <img src="../assets/images/health-home-icon.svg" alt="" />
                                </span>
                                <span v-if="value.id===5" class="icon-holder">
                                    <img src="../assets/images/pa-home-icon.svg" alt="" />
                                </span>
                                <span v-if="value.id===4" class="icon-holder">
                                    <img src="../assets/images/travel-home-icon.svg" alt="" />
                                </span>
                                <h3 v-if="value.name == 'PA'">Personal Accident</h3>
                                <h3 v-else>{{value.name}}</h3>
                                <!-- <h4>Insurance</h4> -->
                            </a>
                            <div class="white-gradient"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="promotion-home">
    <div class="head-text">
        <h2 data-aos="fade-up" data-aos-duration="1000"> Option to select from a variety <br/> of insurance providers </h2>
        <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000"> Competitive coverage solutions <br/> Best-possible rates </p>
    </div>
    <carousel :items-to-show="3.5" :breakpoints="promotionsBreakpoints">
        <Slide v-for="slide in promotion" :key="slide.id">
            <div class="carousel__item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                <div class="promotion-box">
                    <span v-if="slide.id == 1" class="top-badge"> Your Idear Start Here </span>
                    <img :src="slide?.image">
                    <div class="text-box">
                        <h3>{{ slide?.heading }}</h3>
                        <p>{{ slide?.content }}</p>
                        <router-link to="/coming-soon" class="action"></router-link>
                    </div>
                </div>
            </div>
        </Slide>

        <template #addons>
            <Navigation />
            <Pagination />
        </template>
    </carousel>
</section>

<section class="home-partner">
    <div class="container">
        <h2 data-aos="fade-up" data-aos-duration="1000">Our Partners</h2>
        <carousel :items-to-show="6" :breakpoints="partnersBreakpoints" :wrap-around="true" :autoplay="4000">
            <Slide v-for="slide in partners" :key="slide.id">
                <div class="carousel__item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                    <div class="partner-box">
                        <img :src="slide.image" alt="" />
                    </div>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
            </template>
        </carousel>
    </div>
</section>
<section class="best-place">
    <div class="container">
        <div class="row mb-5" data-aos="fade-up" data-aos-duration="1000">
            <div class="col-lg-6">
                <div class="text">
                    <h2>It’s time to take care! Why InXureHub is the best to buy Insurance?</h2>
                </div>
            </div>
            <div class="col-lg-6 text-center">
                <h3 class="tagline">
                    # Banao
                    <span class="typed-text">{{ typeValue }}</span>
                    <span class="blinking-cursor">|</span>
                    <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
                </h3>
            </div>
        </div>
        <div class="row align-items-start">
            <div class="col-lg-5">
                <div class="text">
                    <ul>
                        <li class="clearfix" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="600">
                            <span class="icon-holder">
                                <img src="../assets/images/best-icon1.svg" />
                            </span>
                            <div class="txt">
                                <h4>Quick/Easy procedure:</h4>
                                <h5>Our process is simple, comprising of just three steps: Supply necessary information, compare > evaluate options and enroll immediately.</h5>
                            </div>
                        </li>
                        <li class="clearfix" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="600">
                            <span class="icon-holder">
                                <img src="../assets/images/best-icon2.svg" />
                            </span>
                            <div class="txt">
                                <h4>Convenient online access:</h4>
                                <h5>A feature-rich mobile app and website, with robust capabilities, to simplify the process of selling, purchasing, and managing policies.</h5>
                            </div>
                        </li>
                        <li class="clearfix" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="600">
                            <span class="icon-holder">
                                <img src="../assets/images/best-icon3.svg" />
                            </span>
                            <div class="txt">
                                <h4>Plan comparison/Claim Filing:</h4>
                                <h5>A plan comparison feature that enables you to choose the best option for you, along with an easy-to-use mobile app/web channel that streamlines the process of filing claims.</h5>
                            </div>
                        </li>
                        <li class="clearfix" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="600">
                            <span class="icon-holder">
                                <img src="../assets/images/best-icon4.svg" />
                            </span>
                            <div class="txt">
                                <h4>Fast/Friendly Customer Service:</h4>
                                <h5>Our customer support team is always available and eager to assist you in making the insurance process as seamless and stress-free as possible.</h5>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-7">
                <img data-aos="fade-left" data-aos-duration="1000" data-aos-delay="600" src="../assets/images/bestplace.png" alt="">
            </div>
        </div>
    </div>
</section>

<section class="features">
    <div class="container">
        <div class="row">
            <div class="col-md-4 mb-3">
                <div class="feature-inner" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                    <span class="icon-holder">
                        <img src="../assets/images/online-payment-icon.svg" />
                    </span>
                    <div class="text">
                        <h4>Comprehensive Products</h4>
                        <h5>Catering your needs anywhere, anytime</h5>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="feature-inner" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                    <span class="icon-holder">
                        <img src="../assets/images/online-access-icon.svg" />
                    </span>
                    <div class="text">
                        <h4>Feasible Settlement Process</h4>
                        <h5>Protecting assets with cutting-edge technology</h5>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="feature-inner" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                    <span class="icon-holder">
                        <img src="../assets/images/plan-comparison-icon.svg" />
                    </span>
                    <div class="text">
                        <h4>Best Customer Service</h4>
                        <h5>Backed by 24/7 friendly customer service online</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="inspire-home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col">
                <div class="image-holder" data-aos-duration="1000" data-aos="fade-right">
                    <img src="../assets/images/inspire-home-image.png" alt="" />
                </div>
                <div class="text-box" data-aos="fade-left">
                    <div class="section-top">
                        <!-- <h3 class="font-sacramento">You Inspire Us</h3> -->
                        <h2 class="section-title">Get insured to ensure the sense and security of living – Affordability at its best
“InXureHub offers intensive services & protection</h2>
                    </div>
                    <a href="#" class="btn btn-primary">Read More <img src="../assets/images/icons/arrow-up-right.png" alt=""></a>
                    <!-- <p>It's your goal to live comfortably to and through retirement. Put away a little each month and increase it over time. Your workplace may offer a plan to help.</p> -->
                    
                </div>
            </div>
        </div>
    </div>
</section>

<section class="user-steps">
    <div class="container">
        <div class="row justify-content-between downside-steps steps-holder">
            <div class="col-lg-2">
                <div class="step" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="0">
                    <div class="step-header">
                        <h4>Step 01</h4>
                    </div>
                    <div class="step-body">
                        <h5>select</h5>
                        <p> Know your need of buying insurance Calculate coverage need </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="step" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="200">
                    <div class="step-header">
                        <h4>Step 02</h4>
                    </div>
                    <div class="step-body">
                        <h5>select</h5>
                        <p> Add the amount of insurance coverage you need Compare plans </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="step" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="400">
                    <div class="step-header">
                        <h4>Step 03</h4>
                    </div>
                    <div class="step-body">
                        <h5>select</h5>
                        <p> Filter your search & compare the shortlisted plans Select Plan </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-around upside-steps steps-holder">
            <div class="col-lg-2">
                <div class="step"  data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="600">
                    <div class="step-body">
                        <h5>select</h5>
                        <p> Choose a plan by taking the help of our Pay Online </p>
                    </div>
                    <div class="step-header">
                        <h4>Step 04</h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="step" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="800">
                    <div class="step-body">
                        <h5>select</h5>
                        <p> Make the online payment </p>
                    </div>
                    <div class="step-header">
                        <h4>Step 05</h4>
                    </div>
                </div>
            </div>
        </div>  
        <div class="row justify-content-center" data-aos-duration="1000" data-aos="fade-up">
            <div class="col-md-6 align-center">
                <div class="user-will">
                    <h3> <img src="../assets/images/arrow-right.svg" alt=""> You’ll get the policy </h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-vid">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="slider-inner" data-aos-duration="1000" data-aos="fade-up">
                    <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
                        <Slide v-for="slide in sliderVid" :key="slide">
                            <div class="carousel__item">
                                <iframe width="100%" height="580" :src="slide.link" :title="slide.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </Slide>
                    </Carousel>

                    <Carousel
                        id="thumbnails"
                        :items-to-show="4"
                        :wrap-around="true"
                        v-model="currentSlide"
                        class="thumbnails-slider"
                        ref="carousel"
                    >
                        <Slide v-for="slide in sliderVidThumb" :key="slide.id">
                            <div class="carousel__item" @click="slideTo(slide.id - 1)">
                                <img :src="slide.thumb" />
                            </div>
                        </Slide>
                    </Carousel>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonials-sections">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-5">
                <div class="left-text-box">
                    <h2 data-aos-duration="1000" data-aos="fade-up"> Trusted by Thousands of Happy Customers </h2>
                    <p data-aos-duration="1000" data-aos-delay="100" data-aos="fade-up"> We pride ourselves on offering services that meet the highest standards - the kind of service we would want for ourselves. To see the level of satisfaction of our clients, take a look at the top reviews from our policy holders. </p>
                    <img data-aos-duration="1000" data-aos-delay="200" data-aos="fade-up" src="../assets/images/test-rating.svg" />
                    <span data-aos-duration="1000" data-aos-delay="300" data-aos="fade-up" class="rating-no"> (4.8/5) </span>
                    <span data-aos-duration="1000" data-aos-delay="400" data-aos="fade-up" class="rating-title">Overall Customer Ratings</span>
                </div>
            </div>
            <div class="col-md-7">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="testimonials-box mb-md-4" data-aos-duration="1000" data-aos-delay="500" data-aos="fade-down">
                            <div class="customer-area clearfix">
                                <span class="image-holder">
                                    <img src="../assets/images/avatar3.png" />
                                </span>
                                <div class="text-box">
                                    <span class="title"> Asif Nazeer </span>
                                    <img src="../assets/images/test-rating.svg" />
                                </div>
                            </div>
                            <div class="customer-testmonials-box">
                                <span class="title"> “ Best coverage plan ” </span>
                                <p> Buying InXureHub’s travel insurance before our trip was the best decision I ever made. </p>
                            </div>
                        </div>
                        <div class="testimonials-box" data-aos-duration="1000" data-aos-delay="500" data-aos="fade-up">
                            <div class="customer-area clearfix">
                                <span class="image-holder">
                                    <img src="../assets/images/avatar2.png" />
                                </span>
                                <div class="text-box">
                                    <span class="title"> Alamgir Shahbaz </span>
                                    <img src="../assets/images/test-rating.svg" />
                                </div>
                            </div>
                            <div class="customer-testmonials-box">
                                <span class="title"> “ Best Decision that he made ” </span>
                                <p> Bohat kamal ka experience diya Inxurehub ne. I am a happy customer and will use inxurehub again. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="testimonials-box" data-aos-duration="1000" data-aos-delay="500" data-aos="fade-left">
                            <div class="customer-area clearfix">
                                <span class="image-holder">
                                    <img src="../assets/images/avatar1.png" />
                                </span>
                                <div class="text-box">
                                    <span class="title"> Amber Riaz </span>
                                    <img src="../assets/images/test-rating.svg" />
                                </div>
                            </div>
                            <div class="customer-testmonials-box">
                                <span class="title"> “ Best customer Service ” </span>
                                <p> It was a great experience. I discussed my concerns & got satisfied. Was not sure these kinds of services are available in Pakistan too. Thanks for developing this for us. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="mobile-app">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6" data-aos-duration="1000" data-aos-delay="500" data-aos="fade-right">
                <img src="../assets/images/mobile-hand.png" alt="" class="icon-mobile">
                <div class="text-box">
                    <h2>Robust mobile app</h2>
                    <p>Introducing Easy navigation, 24/7 insurance information access, payment, automated reminders, one-tap policy update, instant claim intimation, and many more.</p>
                    <a href="#" class="playstore"><img src="../assets/images/playstore.png" alt=""></a>
                </div>
            </div>
            <div class="col-lg-6" data-aos-duration="1000" data-aos-delay="500" data-aos="fade-left">
                <img src="../assets/images/mobile-application.png" alt="" class="large-flow">
            </div>
        </div>
    </div>
</section>


<section class="email-newsletter"  data-aos-duration="1000" data-aos="fade-up">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <div class="form-group"><input type="text" class="form-control newsletter-input" placeholder="Enter Your Email"> <button class="newsletter-btn"><i class="fas fa-paper-plane"></i></button></div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
    import AOS from "aos";
    import 'vue3-carousel/dist/carousel.css'
    import Swal from 'sweetalert2/dist/sweetalert2';
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import axios from 'axios'


export default {
  name: 'HomeView',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
      return {
        message: '',
        showIntro: true,
        classes:{},
        filteredServices:{},
        currentSlide: 0,
        typeValue: "",
        typeStatus: false,
        displayTextArray: ["Har Lamha Mehfoz", "Har Lamha Mehfoz", "Har Lamha Mehfoz", "Har Lamha Mehfoz", "Har Lamha Mehfoz"],
        typingSpeed: 100,
        erasingSpeed: 100,
        newTextDelay: 2000,
        displayTextArrayIndex: 0,
        charIndex: 0,
        isTakfulClass: null,
        fetchName: '',
        mobileNumber: '',
        fetchService: '',
        validations:[],
        isNumber: true,
        isSubmitting: false,
        promotionsBreakpoints: {
            320: {
                itemsToShow: 1,
            },
            500: {
                itemsToShow: 2,
            },
            700: {
                itemsToShow: 3.5,
            },
        },
        partnersBreakpoints: {
        // 700px and up
            320: {
                itemsToShow: 3,
            },
            // 1024 and up
            500: {
                itemsToShow: 3,
            },
            700: {
                itemsToShow: 6,
            },
        },
        partners:[
            {id:'2', image: require('../assets/images/partners/2.png')},
            {id:'3', image: require('../assets/images/partners/3.png')},
            {id:'4', image: require('../assets/images/partners/4.png')},
            {id:'5', image: require('../assets/images/partners/5.png')},
            {id:'6', image: require('../assets/images/partners/6.png')},
            {id:'7', image: require('../assets/images/partners/7.png')},
            {id:'8', image: require('../assets/images/partners/alfalah.png')},
            {id:'9', image: require('../assets/images/partners/9.png')},
            {id:'10', image: require('../assets/images/partners/10.png')},
        ],
        promotion:[
            {id:'1', image: require('../assets/images/promotion-banner1.jpg'),  heading: 'Health', content: 'Returns along with life cover'},
            {id:'2', image: require('../assets/images/promotion-banner2.jpg')},
            {id:'3', image: require('../assets/images/promotion-banner3.jpg'), heading: 'Motor', content: 'Affordable Packages'},
            {id:'4', image: require('../assets/images/promotion-banner1.jpg'), heading: 'Travel', content: 'Affordable Packages'},
        ],
        testimonials: [
            { id: '1', title: 'Viezh Robert', city: 'Shanxi, China', image: '../assets/images/user-img1.png', content: '“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there have been no problems. LaslesVPN always the best”.' },
            { id: '2', title: 'Viezh Robert', city: 'Shanxi, China', image: '../assets/images/user-img1.png', content: '“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there have been no problems. LaslesVPN always the best”.' },
            { id: '3', title: 'Viezh Robert', city: 'Shanxi, China', image: '../assets/images/user-img1.png', content: '“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there have been no problems. LaslesVPN always the best”.' },
            { id: '4', title: 'Viezh Robert', city: 'Shanxi, China', image: '../assets/images/user-img1.png', content: '“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there have been no problems. LaslesVPN always the best”.' },
            { id: '5', title: 'Viezh Robert', city: 'Shanxi, China', image: '../assets/images/user-img1.png', content: '“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there have been no problems. LaslesVPN always the best”.' }
        ],
        partnerLogos: [
            { id: '1', image: '../assets/images/user-img1.png' },
            { id: '2', image: '../assets/images/user-img1.png' },
            { id: '3', image: '../assets/images/user-img1.png' },
            { id: '4', image: '../assets/images/user-img1.png' },
            { id: '5', image: '../assets/images/user-img1.png' },
            { id: '6', image: '../assets/images/user-img1.png' },
            { id: '7', image: '../assets/images/user-img1.png' },
            { id: '8', image: '../assets/images/user-img1.png' },
            { id: '9', image: '../assets/images/alfalah.png' }
        ],
        homeTopBanner: [
            { id: '1', superHero: require('../assets/images/superhero1.png') , image: require('../assets/images/main-banner12.jpg'), heading: 'Making Insurance Simple, Transparent & Accessible to everyone', para: '' },
            { id: '2',superHero: require('../assets/images/superhero2.png') ,image: require('../assets/images/main-banner22.jpg'), heading: 'Making Your Adventure Memorable', para: '' },
            { id: '3',superHero: require('../assets/images/superhero3.png'), image: require('../assets/images/main-banner33.jpg'), heading: 'Caring Your Wheelsz', para: '' },
            { id: '4',superHero: require('../assets/images/superhero4.png'), image: require('../assets/images/main-banner44.jpg'), heading: '# Banao Har Lamha Mehfoz', para: 'Secure best insurance plan & enjoy the life at every stage' }
            
        ],
        sliderVid: [
            { id: '1', link: 'https://www.youtube.com/embed/2zb0A1MsLyI' , title: "Buy Insurance Online via mobile app ( Android / IOS ) - Series 1, Episode 3"},
            { id: '2', link: 'https://www.youtube.com/embed/3Cb9G-o0IzU' , title: "Covering The Blue You – Episode 2: How To Buy Insurance? A Step-By-Step Guide!"},
            { id: '3', link: 'https://www.youtube.com/embed/FZbDQtNjp2g' , title: "Covering The Blue You – Episode 1: How to Save Yourself from Unexpected?"},
            { id: '4', link: 'https://www.youtube.com/embed/RAjAvtNyd38' , title: "Save Your Family from Calamity with InXureHub Best Coverage Plan"},
            { id: '5', link: 'https://www.youtube.com/embed/jI2_vNdcbYg' , title: "InXureHub Mobile App Promo - A Step Towards Simple &amp; Accessible Insurance"},
            { id: '6', link: 'https://www.youtube.com/embed/-VZKMb4hnC4' , title: "Welcome to the world of InXureHub"},
            { id: '7', link: 'https://www.youtube.com/embed/Z8PhaJYrMRs' , title: "Inxurehub - Making Insurance Simple, Transparent &amp; Accessible"},
            { id: '8', link: 'https://www.youtube.com/embed/tn2UvZ7jHwk' , title: "Making Insurance Simple, Transparent &amp; Accessible - Buy Best Insurance Plan online on InXureHub.com"},
            { id: '9', link: 'https://www.youtube.com/embed/gdzvrDbCo2Y' , title: "The Best Platform to Buy Insurance Online in Pakistan | Inxurehub.com"},
            { id: '10', link: 'https://www.youtube.com/embed/KNKc87G7C9w' , title: "InXureHub - Digitizing Insurance in Pakistan"}
            
        ],
        sliderVidThumb: [
            { id: '1', thumb: require('../assets/images/vid-thumb1.png')},
            { id: '2', thumb: require('../assets/images/vid-thumb2.png')},
            { id: '3', thumb: require('../assets/images/vid-thumb3.png')},
            { id: '4', thumb: require('../assets/images/vid-thumb4.png')},
            { id: '5', thumb: require('../assets/images/vid-thumb1.png')},
            { id: '6', thumb: require('../assets/images/vid-thumb2.png')},
            { id: '7', thumb: require('../assets/images/vid-thumb1.png')},
            { id: '8', thumb: require('../assets/images/vid-thumb2.png')},
            { id: '9', thumb: require('../assets/images/vid-thumb3.png')},
            { id: '10', thumb: require('../assets/images/vid-thumb4.png')}
            
        ],
      }
    },
    methods:{
        checkNumber() {
            this.isNumber = /^\d*$/.test(this.mobileNumber);
        },
        custom(){
            this.$swal({
                // title: '<i>Custom HTML</i>',
                // add a custom html tags by defining a html method.
                width: 675,
                padding: '0',
                showConfirmButton: false,
                customClass: {
                    popup: 'fetch-number-popup'
                },
                html:
                    `<div class="fetch-number-popup-main">` +
                    `<img src="${require('../assets/images/popup-tick.png')}" alt="" />`+
                    `<h2> Thanks for reaching out!  </h2>` +
                    `<p> We'll be in touch shortly to help you with your query  </p>` +
                    `<img src="${require('../assets/images/popup-dots.png')}" alt="" />`+
                    `<img class="avatar" src="${require('../assets/images/popup-avatar.png')}" alt="" />`+
                    '</div>'
                // showCloseButton: true,
                // showCancelButton: true,
                // focusConfirm: false,
            })
        },
        submitNumber() {
             let _this=this;
            let config = {
                method: 'post',
                url: 'https://inxure.application.zijsoft.com/api/SaveMobileInfo',
                data: {
                    'name':this.fetchName,
                    'mobileNumber':this.mobileNumber,
                    'category':this.fetchService,
                },
            }
            _this.isSubmitting = true;
            axios(config)
            .then(function (response) {
                // console.log(response)
                // if(response.status==500){
                //     _this.validations=response.data.message;
                //     return false;
                // }
                if(response.data.message==200) {
                    _this.mobileNumber = "";
                    _this.fetchName = "";
                    _this.fetchService = "";
                    Swal.fire({
                        timer: 2000,
                        width: 675,
                        padding: '0',
                        showConfirmButton: false,
                        customClass: {
                            popup: 'fetch-number-popup'
                        },
                        timerProgressBar: true,
                        html:`
                            <div class="fetch-number-popup-main"> 
                            <img src="${require('../assets/images/popup-tick.png')}" alt="" />
                            <h2> Thanks for reaching out!  </h2>
                            <p> ${response.data.data}  </p>
                            <img src="${require('../assets/images/popup-dots.png')}" alt="" />
                            <img class="avatar" src="${require('../assets/images/popup-avatar.png')}" alt="" />
                            </div>
                            `
                        // showCloseButton: true,
                        // showCancelButton: true,
                        // focusConfirm: false,
                  })
                }
                if(response.data.message==400) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: response.data.data
                  })
                    // let user = response.data.data;
                    // localStorage.setItem('user', JSON.stringify(user));
                    // let LocalToken = response.data.data.token;
                    // localStorage.setItem('local_token', LocalToken);

                    // let OzoneToken = response.data.data.accessToken;
                    // localStorage.setItem('ozone_token', OzoneToken);
                    // _this.$router.push('/')
                    // return false;
                }

            })
            .catch(function (response) {
              _this.validations=(response.response.data.errors);
            //   console.log(_this.validations)
            })
            .finally(() => {
                _this.isSubmitting = false;
            }) 
        },
        slideTo(val) {
            this.currentSlide = val
        },
        homeIntro: function () {
            var v = this;
            setTimeout(function () {
              v.showIntro = false
            }, 2000);
        },
        getService(e){
            localStorage.setItem('service', JSON.stringify(e));
            this.$router.push('/feature-form')
        },
        filtertype(filter){
            let response = this.classes.filter( value => value.is_takaful == filter )
            let vm = this
            this.filteredServices = response
            if(filter == 'No'){
                vm.isTakfulClass = false
            }else if(filter == 'Yes'){
                vm.isTakfulClass = true
            }
            console.log(vm.isTakfulClass)
        },
        get:function(){
            let url='https://inxure.application.zijsoft.com/api/classes';
            let vm = this
            axios.get(url)
                .then((response) => {
                    console.log('classes response')
                    console.log(response)
                    if(response.status==200) {
                        this.classes  = response.data.data
                        this.filteredServices = this.classes.filter( value => value.is_takaful == 'No' )
                        // console.log(this.classes)
                        // localStorage.setItem('user', JSON.parse(service_name));
                        // return false;
                    }
                })
                .catch((e) => {
                    // this.errors.push(e);
                    console.log(e)
                });
        },
        typeText() {
            if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(
                this.charIndex
                );
                this.charIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            } else {
                this.typeStatus = false;
                setTimeout(this.eraseText, this.newTextDelay);
            }
            },
            eraseText() {
            if (this.charIndex > 0) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(
                0,
                this.charIndex - 1
                );
                this.charIndex -= 1;
                setTimeout(this.eraseText, this.erasingSpeed);
            } else {
                this.typeStatus = false;
                this.displayTextArrayIndex += 1;
                if (this.displayTextArrayIndex >= this.displayTextArray.length)
                this.displayTextArrayIndex = 0;
                setTimeout(this.typeText, this.typingSpeed + 1000);
            }
        },
    },
    created() {
        this.get();
        setTimeout(this.typeText, this.newTextDelay + 200);
    },
    mounted () {
        this.homeIntro(),
        AOS.init();
    }
}
</script>
