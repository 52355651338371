<template>
    <section class="login">
      <div class="container-fluid">
          <img src="assets/images/logo-icon.png" alt="" class="login-logo">
        <!-- <div class="row">
          <div class="col-lg-6">
                  <img src="assets/images/logo-icon.png" alt="" class="login-logo">
              </div>
              <div class="col-lg-6">  
              </div>
        </div> -->
        <div class="text-box-login">
          <h1 class="font-sacramento">Secure best</h1>
          <h2>insurance plan & enjoy the life at every stage</h2>
          <h3>Banao Har Lamha Mehfooz</h3>
        </div>
        <div class="login-card">
              <h2>Hello Again !</h2>
              <p>Please Sign in to continue with us</p>
              <span class="text-danger mb-3" v-if="validations.length > 0">{{validations}}</span>
              <div class="form-group gradient-border">
                <input type="text" v-model="user_name" placeholder="User Name" class="form-control">
                <span class="text-danger" v-if="validations.user_name">{{validations.user_name[0]}}</span>
              </div>
              <div class="form-group gradient-border group_btn">
                <input :type="passwordType" v-model="password" placeholder="Passsword" class="form-control"> <span class="btn_pwd" @click="viewPassword"><i class="fa fa-eye"></i></span>
                <span class="text-danger" v-if="validations.password">{{validations.password[0]}}</span>
              </div>
              <div class="row mb-3">
                  <div class="col">
                      <div class="chk-inner">
                          <label class="check-holder">
                              Remember Password
                              <input type="checkbox" id="remember_pwd" name="remember_pwd" value="Yes">
                              <span class="checkmark"></span>
                          </label>
                      </div>
                  </div>
                  <div class="col align-right">
                    <router-link to="/forgot-password">Forgot Password?</router-link>
                  </div>
              </div>
              <div class="form-group"><button class="btn btn-signin" @click="loginFormHandle" >Sign IN</button></div>
              <div class="align-center">
                <router-link class="register-btn" to="/signup"> Not a User? Please Register</router-link>
              </div>
                
          </div>
      </div>
  </section>
</template>
<script>
import axios from 'axios'
export default {
  
  data() {
      return {
        user_name: '',
        password: '',
        validations:[],
        passwordType: 'password'
      }
    },
    methods: {
        viewPassword(){
          this.passwordType = this.passwordType === "password" ? "text" : "password";
        },
         async loginFormHandle() {
             let _this=this;
            _this.validations=[]
            let config = {
                method: 'post',
                url: 'https://inxure.application.zijsoft.com/api/login',
                data: {
                    'user_name':this.user_name,
                    'password':this.password
                },
            }

            axios(config)
            .then(function (response) {
                console.log(response)
                if(response.status==500){
                    _this.validations=response.data.message;
                    return false;
                }
                if(response.status==200) {
                    let user = response.data.data;
                    localStorage.setItem('user', JSON.stringify(user));
                    let LocalToken = response.data.data.token;
                    localStorage.setItem('local_token', LocalToken);

                    let OzoneToken = response.data.data.accessToken;
                    localStorage.setItem('ozone_token', OzoneToken);
                    _this.$router.push('/')
                    return false;
                }

            })
            .catch(function (response) {
              _this.validations=(response.response.data.errors);
              console.log(_this.validations)
               if(response.response.status==404){
                  
               }
            });
        },
    },
    mounted(){
      let user = localStorage.getItem('user')
      if(user){
        console.log(user);
        this.$router.push('/')
      }
    },
    watch:{
        validations:function () {
      }
    }
}
</script>