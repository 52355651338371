<template>
    <header 
        class="header" 
        :class="{ 'fixed-header': scrolled }"
        v-show="handleScroll"
        >
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="header-inner">
                        <div class="logo">
                            <router-link to="/" class="logo-holder">
                                <img src="../../assets/images/logo_f.svg" alt="" />
                            </router-link>
                        </div>
                        <span class="mobile-menu" @click="openDrop"> <fa-icon icon="bars" /> </span>
                        <div class="manu-holder">
                            <ul class="menu">
                                <li class="menu-list">

                                    <router-link to="/"> 
                                        <span class="icon">
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 12.13V6.16459C1 5.58752 1.26642 5.04278 1.72193 4.6885L6.60193 0.892943C7.27721 0.367727 8.22279 0.367727 8.89807 0.892942L13.7781 4.6885C14.2336 5.04278 14.5 5.58752 14.5 6.16459V12.13C14.5 13.1628 13.6628 14 12.63 14H10.5C10.2239 14 10 13.7761 10 13.5V10.8987C10 10.6355 9.95695 10.3693 9.81766 10.146C9.63545 9.85386 9.39228 9.60234 9.10303 9.40951C8.70253 9.14251 8.23167 9 7.75 9C7.26833 9 6.79747 9.14251 6.39697 9.40951C6.10772 9.60234 5.86455 9.85386 5.68234 10.146C5.54305 10.3693 5.5 10.6355 5.5 10.8987V13.5C5.5 13.7761 5.27614 14 5 14H2.87C1.83723 14 1 13.1628 1 12.13Z" stroke="#A8A8A8" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <span class="text"> Home  </span>
                                    </router-link>
                                </li>
                                <li class="menu-list">
                                    <router-link to="/">  
                                        <span class="icon">
                                            <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1H6.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M1 5.25H6.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M11.5 9.5H1" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M9.5 1.25L11.5 3.25L9.5 5.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <span class="text">
                                            Services
                                        </span>
                                    </router-link>
                                    <ul class="sumenu">

                                        
                                        <li class="submenu-item" v-for="(value , key) in classes" :key="key">
                                            <a  href="#"
                                                @click.prevent="getService(value)" 
                                                :class="{ motor: value.name == 'Motor' || value.name == 'Motor Takaful', health: value.name == 'Health', pa: value.name == 'PA', travel: value.name == 'Travel'}"> {{value.name}} </a>
                                        </li>
                                        <!-- <li class="submenu-item">
                                            <router-link to="/" class="health"> Health </router-link>
                                        </li>
                                        <li class="submenu-item">
                                            <router-link to="/" class="pa"> PA </router-link>
                                        </li>
                                        <li class="submenu-item">
                                            <router-link to="/" class="travel"> Travel </router-link>
                                        </li>
                                        <li class="submenu-item">
                                            <router-link to="/" class="home"> Home </router-link>
                                        </li> -->
                                    </ul>
                                </li>
                                <li class="menu-list">
                                    <router-link to="/coming-soon"> 
                                        <span class="icon">
                                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 6.25L11.5 6.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M1 1L1 11.5" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6.75 3.25L3.75 6.25L6.75 9.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                        </span>
                                        <span class="text">
                                            Claims
                                        </span>
                                    </router-link>
                                </li>
                                <li class="menu-list">
                                    <router-link to="/coming-soon"> 
                                        <span class="icon">
                                            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4H4.75L7.75 1V11.5L4.75 8.5H1V4Z" stroke="#A8A8A8" stroke-width="1.5" stroke-linejoin="round"/>
                                                <path d="M10.25 8.75C10.694 8.54519 11.0735 8.19836 11.3405 7.75336C11.6075 7.30837 11.75 6.78519 11.75 6.25C11.75 5.71481 11.6075 5.19163 11.3405 4.74664C11.0735 4.30164 10.694 3.95481 10.25 3.75" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M11 11.5C12.036 11.0699 12.9215 10.3416 13.5445 9.40706C14.1675 8.47257 14.5 7.3739 14.5 6.25C14.5 5.1261 14.1675 4.02743 13.5445 3.09294C12.9215 2.15845 12.036 1.4301 11 1" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <span class="text">
                                            Promotions
                                        </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="nav-right">
                            <ul class="login-top">
                                <li class="download-app">
                                    <span class="download-app-label"> Download app </span>
                                    <a class="icon-holder" href="https://apps.apple.com/pk/app/inxurehub/id6444064934" target="_blank"> <img src="../../assets/images/store-icon1.svg" /> </a>
                                    <a class="icon-holder" href="https://play.google.com/store/apps/details?id=com.ozoneddigital.insureHub&hl=en&gl=US" target="_blank"> <img src="../../assets/images/store-icon2.svg" /> </a>
                                    
                                </li>
                                <!-- <li>
                                    <span style="margin-right: 50px;" v-if="loggedIn"> Hi <b> {{user_name}} </b>, </span>
                                    <router-link to="/signup" v-else class="register-btn"> Not a User? Please Register </router-link>
                                </li> -->
                                <li>
                                    <button v-if="loggedIn" @click="logOut" class="btn btn-primary"> Logout </button>
                                    <router-link to="/login" v-else class="btn btn-primary"> Login </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="mobile-manu-holder" :class="{show: isDropdown}" id="menu">
                            <ul class="mobile-menu">
                                <li class="menu-list">

                                    <router-link to="/" @click="isDropdown = false"> 
                                        <span class="icon">
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 12.13V6.16459C1 5.58752 1.26642 5.04278 1.72193 4.6885L6.60193 0.892943C7.27721 0.367727 8.22279 0.367727 8.89807 0.892942L13.7781 4.6885C14.2336 5.04278 14.5 5.58752 14.5 6.16459V12.13C14.5 13.1628 13.6628 14 12.63 14H10.5C10.2239 14 10 13.7761 10 13.5V10.8987C10 10.6355 9.95695 10.3693 9.81766 10.146C9.63545 9.85386 9.39228 9.60234 9.10303 9.40951C8.70253 9.14251 8.23167 9 7.75 9C7.26833 9 6.79747 9.14251 6.39697 9.40951C6.10772 9.60234 5.86455 9.85386 5.68234 10.146C5.54305 10.3693 5.5 10.6355 5.5 10.8987V13.5C5.5 13.7761 5.27614 14 5 14H2.87C1.83723 14 1 13.1628 1 12.13Z" stroke="#A8A8A8" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <span class="text"> Home  </span>
                                    </router-link>
                                </li>
                                <li class="menu-list">
                                    <a data-bs-toggle="collapse" href="#sumenu" aria-expanded="false" aria-controls="menu">  
                                        <span class="icon">
                                            <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1H6.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M1 5.25H6.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M11.5 9.5H1" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M9.5 1.25L11.5 3.25L9.5 5.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <span class="text">
                                            Services
                                        </span>
                                    </a>
                                    <ul id="sumenu" class="sumenu collapse">                                        
                                        <li class="submenu-item" v-for="(value , key) in classes" :key="key">
                                            <a  href="#"
                                                @click.prevent="getService(value)" 
                                                :class="{ motor: value.name == 'Motor' || value.name == 'Motor Takaful', health: value.name == 'Health', pa: value.name == 'PA', travel: value.name == 'Travel'}"> {{value.name}} </a>
                                        </li>
                                        <!-- <li class="submenu-item">
                                            <router-link to="/" class="health"> Health </router-link>
                                        </li>
                                        <li class="submenu-item">
                                            <router-link to="/" class="pa"> PA </router-link>
                                        </li>
                                        <li class="submenu-item">
                                            <router-link to="/" class="travel"> Travel </router-link>
                                        </li>
                                        <li class="submenu-item">
                                            <router-link to="/" class="home"> Home </router-link>
                                        </li> -->
                                    </ul>
                                </li>
                                <li class="menu-list">
                                    <router-link to="/coming-soon" @click="isDropdown = false"> 
                                        <span class="icon">
                                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 6.25L11.5 6.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M1 1L1 11.5" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6.75 3.25L3.75 6.25L6.75 9.25" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                        </span>
                                        <span class="text">
                                            Claims
                                        </span>
                                    </router-link>
                                </li>
                                <li class="menu-list">
                                    <router-link to="/coming-soon" @click="isDropdown = false"> 
                                        <span class="icon">
                                            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4H4.75L7.75 1V11.5L4.75 8.5H1V4Z" stroke="#A8A8A8" stroke-width="1.5" stroke-linejoin="round"/>
                                                <path d="M10.25 8.75C10.694 8.54519 11.0735 8.19836 11.3405 7.75336C11.6075 7.30837 11.75 6.78519 11.75 6.25C11.75 5.71481 11.6075 5.19163 11.3405 4.74664C11.0735 4.30164 10.694 3.95481 10.25 3.75" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M11 11.5C12.036 11.0699 12.9215 10.3416 13.5445 9.40706C14.1675 8.47257 14.5 7.3739 14.5 6.25C14.5 5.1261 14.1675 4.02743 13.5445 3.09294C12.9215 2.15845 12.036 1.4301 11 1" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <span class="text">
                                            Promotions
                                        </span>
                                    </router-link>
                                </li>
                                <li class="menu-list">
                                    <button v-if="loggedIn" @click="logOut" class="btn btn-primary"> Logout </button>
                                    <router-link to="/login" @click="isDropdown = false" v-else class="btn btn-primary"> Login </router-link>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
        </div>
    </header>

    

</template>
<script>
    import AOS from "aos";
    import axios from 'axios'
    export default {
        name: 'Header',
        components:{
        },
        data(){
            return{
                user_name: '',
                loggedIn: false,
                submenu: null,
                limitPosition: 500,
                scrolled: false,
                lastPosition: 0,
                classes:{},
                isDropdown: false
            }
        },
        methods:{
            openDrop(){
                this.isDropdown = !this.isDropdown
            },
            get:function(){
                let url='https://inxure.application.zijsoft.com/api/classes';
                let vm = this
                axios.get(url)
                    .then((response) => {
                        console.log(response)
                        if(response.status==200) {
                            this.classes = response.data.data
                            // console.log(this.classes)
                            // localStorage.setItem('user', JSON.parse(service_name));
                            // return false;
                        }
                    })
                    .catch((e) => {
                        // this.errors.push(e);
                        console.log(e)
                    });
            },
            getService(e){
                localStorage.setItem('service', JSON.stringify(e));
                this.$router.push('/feature-form')
                this.isDropdown = false
                // const currentRoute = this.$route.path;
                // if (currentRoute === '/feature-form') {
                //     window.location.reload();
                // } else {
                //     localStorage.setItem('service', JSON.stringify(e));
                //     this.$router.push('/feature-form');
                //     this.isDropdown = false;
                // }
            },
            logOut(){
                localStorage.removeItem("user");
                localStorage.removeItem("local_token");
                localStorage.removeItem("ozone_token");
                this.loggedIn= !this.loggedIn
                this.user_name = ""
            },
            getUser(){
                let user = localStorage.getItem('user')
                let _this = this;
                
                if(user){
                    _this.user_name = JSON.parse(user).first_name
                    _this.loggedIn= true
                }
            },
            handleScroll() {
                if (
                    this.lastPosition < window.scrollY &&
                    this.limitPosition < window.scrollY
                ) {
                    this.scrolled = true;
                    // move up!
                }

                if (this.lastPosition > window.scrollY) {
                    this.scrolled = false;
                    // move down
                }

                this.lastPosition = window.scrollY;
                // this.scrolled = window.scrollY > 250;
            },
        },
        beforeMount() {
            window.addEventListener("scroll", this.handleScroll);
        },
        beforeUnmount() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        created() {
            setInterval(() => {
                this.getUser()
            }, 1000)
            this.get();
        },
        beforeCreate(){
        },
        mounted(){
            AOS.init();
        },
        watch: {
        },
    }
</script>