<template>
	<div class="contact-main">
		<div class="inner-banner">
			<img src="../assets/images/contact-banner.jpg" class="w-100" />
		</div>
		<div class="content">
			<div class="container mb-md-5">
				<div class="row">
					<div class="col head-section mb-5">
						<h1> We’d Love To Help You </h1>
						<p> Please feel free to get in touch using the form below. We'd love to hear your thoughts & answer any questions you may have! </p>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="row contact-cards mb-5">
					<div class="col-md-4">
						<div class="contact-card">
							<span class="icon-holder">
								<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clip-path="url(#clip0_2136_8181)">
								<path d="M27.0157 24.5991L18 33.6147L8.98434 24.5991C7.20124 22.8159 5.98693 20.5441 5.49498 18.0708C5.00303 15.5976 5.25553 13.034 6.22056 10.7042C7.18558 8.3745 8.81978 6.38323 10.9165 4.98225C13.0132 3.58127 15.4783 2.8335 18 2.8335C20.5217 2.8335 22.9868 3.58127 25.0835 4.98225C27.1802 6.38323 28.8144 8.3745 29.7795 10.7042C30.7445 13.034 30.997 15.5976 30.505 18.0708C30.0131 20.5441 28.7988 22.8159 27.0157 24.5991ZM18 18.4167C18.7515 18.4167 19.4721 18.1182 20.0035 17.5869C20.5348 17.0555 20.8333 16.3348 20.8333 15.5834C20.8333 14.8319 20.5348 14.1113 20.0035 13.5799C19.4721 13.0486 18.7515 12.7501 18 12.7501C17.2486 12.7501 16.5279 13.0486 15.9965 13.5799C15.4652 14.1113 15.1667 14.8319 15.1667 15.5834C15.1667 16.3348 15.4652 17.0555 15.9965 17.5869C16.5279 18.1182 17.2486 18.4167 18 18.4167Z" fill="#09121F"/>
								</g>
								<defs>
								<clipPath id="clip0_2136_8181">
								<rect width="34" height="34" fill="white"/>
								</clipPath>
								</defs>
								</svg>
							</span>
							<h2> Address </h2>
							<p> F7, 1st Floor Zam Zam Tower, 29-E Main Market, Gulberg 2, Lahore </p>
						</div>
					</div>
					<div class="col-md-4">
						<div class="contact-card">
							<span class="icon-holder">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M27.6 30C10.2 30 0 19.8 0 2.40001V2C0 0.895432 0.895432 0 2 0L10.3002 9.73758e-06C10.5763 9.99864e-06 10.7997 0.223452 10.8081 0.499467C10.8761 2.74217 11.3836 6.09984 12.331 7.93842C12.4637 8.19594 12.3866 8.52206 12.1351 8.66579L8.4002 10.8C8.4002 16.2 13.8002 21.6 19.8002 21.6L21.3634 17.9525C21.4865 17.6652 21.841 17.5588 22.1204 17.6989C23.9709 18.627 27.2815 19.1248 29.5005 19.1921C29.7765 19.2005 30 19.4238 30 19.7V28C30 29.1046 29.1046 30 28 30H27.6Z" fill="#1B3764"/>
								</svg>
							</span>
							<h2> Make a Call </h2>
							<p> +9242-36408595 <br/>  +92-33-66-99-99-11 </p>
						</div>
					</div>
					<div class="col-md-4">
						<div class="contact-card">
							<span class="icon-holder">
								<svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0 21.13V1.87C0 0.837228 0.837227 0 1.87 0H15.881H27.7014C28.7342 0 29.5714 0.837228 29.5714 1.87V21.13C29.5714 22.1628 28.7342 23 27.7014 23H1.87C0.837227 23 0 22.1628 0 21.13Z" fill="#09121F"/>
									<path d="M1.64355 1.64258L13.6304 10.1332C14.323 10.6238 15.2498 10.6238 15.9424 10.1332L27.9293 1.64258" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</span>
							<h2> Send a Mail </h2>
							<p> letstalk@inxurehub.com </p>
						</div>
					</div>
				</div>
			</div>
			<div class="contact-form">
				<div class="container">
					<div class="row">
						<div class="col head-section mb-md-5">
							<h1> We make it easy </h1>
							<p> Drop us a line by using the below form </p>
						</div>
					</div>
					<div class="row mt-5">
						<div class="col-md-4">
							<div class="form-group">
								<input placeholder="Name" type="text" class="form-control" />
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<input placeholder="email" type="text" class="form-control" />
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<input placeholder="Subject" type="text" class="form-control" />
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<textarea class="form-control" name="" id="" cols="30" rows="10"></textarea>
							</div>
						</div>
						<div class="col-md-12 align-right">
							<button class="btn btn-primary"> Submit <img src="../assets/images/icons/arrow-up-right.png" alt=""> </button>
						</div>
					</div>
				</div>
			</div>
			<div class="contact-numbers">
				<div class="container">
					<div class="row">
						<div class="col">
							<ul>
								<li>
									<span class="number"> 12+ </span>
									<p> Years Of Experiance </p>
								</li>
								<li>
									<span class="number"> 1000+ </span>
									<p> Happy Customers </p>
								</li>
								<li>
									<span class="number"> 4.8+ </span>
									<p> App Rating </p>
								</li>
								<li>
									<span class="number"> 10+ </span>
									<p> Insurance Companies </p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
